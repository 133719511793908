import React, { useEffect, useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useObserver } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { DropdownList } from 'react-widgets'
import { toast } from 'react-toastify';
import { GlobalHotKeys } from 'react-hotkeys';
import moment from 'moment';

import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';

import CustomerUpdateStore from '../../../../stores/CustomerUpdateStore';
import NoteSearchStore from '../../../../stores/NoteSearchStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';
import AuthStore from '../../../../stores/AuthStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as rts from '../../../../constants/routes';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as ch from '../../../../utilities/customerHelper';
import * as ph from '../../../../utilities/personHelper';
import * as sys from '../../../../utilities/systemHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as ah from '../../../../utilities/addressHelper';

import './UpdateContactInformation.scss';

function UpdateContactInformation(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const primaryPhoneTypeRef = useRef(null);
    const preferredContactMethodRef = useRef(null);
    const auth = useContext(AuthStore);
    const customer = useContext(CustomerUpdateStore);
    const noteSearch = useContext(NoteSearchStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const [hasPrimaryNumberExtension, setHasPrimaryNumberExtension] = useState((customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.hasExtension ? !!customer.data.primaryPhoneNumber.hasExtension : false));
    const [hasSecondaryNumberExtension, setHasSecondaryNumberExtension] = useState((customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.hasExtension ? !!customer.data.secondaryPhoneNumber.hasExtension : false));
    const cache = useContext(CacheStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        setHasPrimaryNumberExtension((customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.hasExtension ? !!customer.data.primaryPhoneNumber.hasExtension : false));
        setHasSecondaryNumberExtension((customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.hasExtension ? !!customer.data.secondaryPhoneNumber.hasExtension : false));
    }, [customer.isReady]) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (customer.hasContactUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleEmailAddressChange = event => {
        customer.hasContactUnsavedChanges = true;
        customer.data.emailAddress = event.target.value;
    }

    const handlePrimaryPhoneNumberChange = event => {
        const newNumberOnly = event.target.value;
        const newNumber = newNumberOnly + (customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.extension ? `x${customer.data.primaryPhoneNumber.extension}` : '');

        customer.hasContactUnsavedChanges = true;

        if (customer.data.primaryPhoneNumber) {
            customer.data.primaryPhoneNumber.number = newNumber;
            customer.data.primaryPhoneNumber.numberOnly = newNumberOnly;
        } else {
            customer.data.primaryPhoneNumber = { number: newNumber, numberOnly: newNumberOnly };
        }
    }

    const handlePrimaryPhoneNumberExtensionChange = event => {
        const newExtension = event.target.value;
        const newNumber = (customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.numberOnly ? customer.data.primaryPhoneNumber.numberOnly : '') + (newExtension ? `x${newExtension}` : '');

        customer.hasContactUnsavedChanges = true;

        if (customer.data.primaryPhoneNumber) {
            customer.data.primaryPhoneNumber.number = newNumber;
            customer.data.primaryPhoneNumber.extension = newExtension;
        } else {
            customer.data.primaryPhoneNumber = { number: newNumber, extension: newExtension };
        }
    }

    const handlePrimaryPhoneNumberTypeChange = event => {
        const newType = event.target.value;

        customer.hasContactUnsavedChanges = true;

        if (customer.data.primaryPhoneNumber) {
            customer.data.primaryPhoneNumber.type = newType;
        } else {
            customer.data.primaryPhoneNumber = { type: newType };
        }

        if (newType === 'Mobile') {
            setHasPrimaryNumberExtension(false);
        }
    }

    const handlePrimaryPhoneNumberHasExtensionChange = event => {
        const newHasExtension = !hasPrimaryNumberExtension;
        setHasPrimaryNumberExtension(newHasExtension);
    }

    const handleSecondaryPhoneNumberChange = event => {
        const newNumberOnly = event.target.value;
        const newNumber = newNumberOnly + (customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.extension ? `x${customer.data.secondaryPhoneNumber.extension}` : '');

        customer.hasContactUnsavedChanges = true;

        if (customer.data.secondaryPhoneNumber) {
            customer.data.secondaryPhoneNumber.number = newNumber;
            customer.data.secondaryPhoneNumber.numberOnly = newNumberOnly;
        } else {
            customer.data.secondaryPhoneNumber = { number: newNumber, numberOnly: newNumberOnly };
        }
    }

    const handleSecondaryPhoneNumberExtensionChange = event => {
        const newExtension = event.target.value;
        const newNumber = (customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.numberOnly ? customer.data.secondaryPhoneNumber.numberOnly : '') + (newExtension ? `x${newExtension}` : '');

        customer.hasContactUnsavedChanges = true;

        if (customer.data.secondaryPhoneNumber) {
            customer.data.secondaryPhoneNumber.number = newNumber;
            customer.data.secondaryPhoneNumber.extension = newExtension;
        } else {
            customer.data.secondaryPhoneNumber = { number: newNumber, extension: newExtension };
        }
    }

    const handleSecondaryPhoneNumberTypeChange = event => {
        const newType = event.target.value;

        customer.hasContactUnsavedChanges = true;

        if (customer.data.secondaryPhoneNumber) {
            customer.data.secondaryPhoneNumber.type = newType;
        } else {
            customer.data.secondaryPhoneNumber = { type: newType };
        }

        if (newType === 'Mobile') {
            setHasSecondaryNumberExtension(false);
        }
    }

    const handleSecondaryPhoneNumberHasExtensionChange = event => {
        const newHasExtension = !hasSecondaryNumberExtension;
        setHasSecondaryNumberExtension(newHasExtension);
    }

    const handleSpokenLanguageChange = event => {
        customer.data.spokenLanguage = event.target.value ? event.target.value : null;
        customer.hasContactUnsavedChanges = true;
    }

    const handleCommunicationLanguageChange = event => {
        customer.data.communicationLanguage = event.target.value ? event.target.value : null;
        customer.hasContactUnsavedChanges = true;
    }

    const handlePreferredContactMethodChange = event => {
        customer.hasContactUnsavedChanges = true;
        customer.data.preferredContactMethod = event.target.value;
    }

    const handleCommunicationConsentChange = event => {
        customer.hasContactUnsavedChanges = true;

        if (event.target.value === 'true') {
            customer.data.communicationConsent = true;
        }
        else if (event.target.value === 'false') {
            customer.data.communicationConsent = false;
        }
        else {
            customer.data.communicationConsent = null;
        }
    }

    const handleAutoReminderChange = event => {
        customer.hasContactUnsavedChanges = true;
        customer.data.autoReminderDisabled = !event.target.checked;
    }

    const handlePrimaryContactChange = (relatedParty) => {
        customer.hasContactUnsavedChanges = true;
        customer.data.primaryContactPersonId = relatedParty && relatedParty.id ? relatedParty.id : null;
        customer.data.primaryContactPerson = relatedParty && relatedParty.id ? relatedParty : null;
    }

    const handleEmergencyContactChange = (relatedParty) => {
        customer.hasContactUnsavedChanges = true;
        customer.data.emergencyContactPersonId = relatedParty && relatedParty.id ? relatedParty.id : null;
        customer.data.emergencyContactPerson = relatedParty && relatedParty.id ? relatedParty : null;
    }

    const handleAddRelationshipClick = event => {
        quickDrawer.activateQuickDrawer('customer', 'relationship-create', null, handleLinkedRelationshipAddSuccess, handleLinkedRelationshipAddCancel);
    }

    const handleLinkedRelationshipAddSuccess = (result) => {
        if (result && result.updated) {
            customer.loadRelationships();
            toast.dark(() => <p data-linked-rel-added>Linked relationship added.</p>);
        }
    }

    const handleLinkedRelationshipAddCancel = () => {
    }

    const handleContactInstructions = () => {
        noteSearch.initialize(customer.data.id, null, 'contactInstruction', false, true);
        quickDrawer.activateQuickDrawer('note', 'view', { title: 'Communication Instructions', icon: 'fal fa-comment-alt-exclamation', renderFilters: false });
    }

    const handleCommunicationSubscriptionLink = event => {
        ch.copyCommunicationPreferenceUrl(customer.data);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current)) {
            const isPrimaryPhoneMobile = customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.type === 'Mobile';
            const isSecondaryPhoneMobile = customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.type === 'Mobile';

            if (customer.data.preferredContactMethod === 'SMS' && !(isPrimaryPhoneMobile || isSecondaryPhoneMobile)) {
                primaryPhoneTypeRef.current.classList.add('has-error');
                preferredContactMethodRef.current.classList.add('has-error');
                toast.error(() => <p>Must provide mobile number for SMS.</p>, { position: 'top-center', style: { width: '380px' } });
            }
            else {
                primaryPhoneTypeRef.current.classList.remove('has-error');
                preferredContactMethodRef.current.classList.remove('has-error');

                if (customer.hasContactUnsavedChanges) {
                    const updated = customer.hasContactUnsavedChanges;

                    customer.updateContactInformation()
                        .then(data => {
                            if (isMounted.current) {
                                if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                                    props.onSuccess(event, { updated: updated, data: data });
                                }
                            }
                        })
                } else {
                    if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                        props.onSuccess(event, { updated: false, data: null });
                    }
                }
            }
        }
    }

    const renderCustomer = () => {
        return <div
            className='profile-wrapper'
        >
            <div className='profile'>
                <span
                    className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(customer.data)} fw-500`}
                    title={ph.getFullName(customer.data)}
                >
                    {customer.data.initials}
                </span>
            </div>
            <div className='description flex-1'>
                <Link to={`${rts.Customers.Home}/${customer.data.id}`} className='name text-gray-700' onClick={quickDrawer.deactivateAll}>{ph.getFullName(customer.data, true)}
                    {
                        customer.data.dateOfBirth || customer.data.sex || customer.data.gender || customer.data.pronoun ?
                            <small className='text-nowrap ml-2'>({`${ph.getAge(customer.data.dateOfBirth)} ${ph.getSexGenderPronounDisplay(customer.data)}`.trim()})</small> : null
                    }
                </Link>
                {
                    customer.data.address && customer.data.address.country ?
                        <div className='info'>{ah.getAddressHtml(customer.data.address)}</div> : null
                }
            </div>
        </div>
    }

    const isEmailRequired = () => {
        return customer.data && customer.data.preferredContactMethod === 'Email';
    }

    const isPhoneRequired = () => {
        return customer.data &&
            (
                customer.data.preferredContactMethod === 'Phone' || customer.data.preferredContactMethod === 'SMS'
            );
    }

    return useObserver(() => <>
        <>
            {
                (props.drawer === quickDrawer.drawerOpened) ?
                    <GlobalHotKeys
                        keyMap={{
                            close: ['esc'],
                        }}
                        handlers={{
                            close: event => {
                                handleCancel(event)
                            },
                        }}
                        allowChanges={true}
                    /> : null
            }
        </>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <fieldset disabled={customer.isSaving}>
                <div className='update-customer-contact-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('customer', 'contact')}
                        action='Update Customer'
                        category='Contact'
                        className='customers'
                        onCancel={handleCancel}
                    />
                    <div className='quick-drawer-body'>
                        {
                            customer.isReady && customer.data ?
                                <FadeIn>
                                    <div className='body-content'>
                                        {
                                            customer.data.emailAddress && !customer.data.hasValidEmailAddress && !!customer.data.emailBouncedReason ?
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {
                                                                customer.data.emailBouncedReason === 'SpamComplaint' ?
                                                                    <div className='alert alert-danger px-3 py-2 mb-0' role='alert'>
                                                                        <div className='d-flex w-100'>
                                                                            <div className='flex-1'>
                                                                                <strong className='d-block'>
                                                                                    SPAM Complaint
                                                                                </strong>
                                                                                <span>
                                                                                    {auth.currentTenant.displayName} cannot send emails to this address.
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div> : null
                                                            }
                                                            {
                                                                customer.data.emailBouncedReason === 'Unsubscribed' ?
                                                                    <div className='alert alert-warning px-3 py-2 mb-0' role='alert'>
                                                                        <div className='d-flex w-100'>
                                                                            <div className='flex-1'>
                                                                                <strong className='d-block'>
                                                                                    Unsubscribed on {sys.getFormattedShortDate(moment.utc(customer.data.communicationUnsubscribedDateUtc).local())}
                                                                                </strong>
                                                                                <span>
                                                                                    Communication consent can only be updated by the recipient via <button type='button' className='btn btn-link btn-link-inline' onClick={handleCommunicationSubscriptionLink}>this link</button>.
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div> : null
                                                            }
                                                            {
                                                                customer.data.emailBouncedReason === 'NotFound' || customer.data.emailBouncedReason === 'Disabled' ?
                                                                    <div className='alert alert-warning px-3 py-2 mb-0' role='alert'>
                                                                        <div className='d-flex w-100'>
                                                                            <div className='flex-1'>
                                                                                <strong className='d-block'>
                                                                                    Invalid Email Address
                                                                                </strong>
                                                                                {customer.data.emailBouncedReason === 'NotFound' ? <>The inbox not found.</> : null}
                                                                                {customer.data.emailBouncedReason === 'Disabled' ? <>The inbox for is not active.</> : null}
                                                                                {customer.data.emailBouncedReason !== 'NotFound' && customer.data.emailBouncedReason !== 'Disabled' ? <><span>Unknown</span></> : null}
                                                                            </div>
                                                                        </div>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </section> : null
                                        }
                                        <section className='customer'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group mb-0'>
                                                        <label><small>Customer</small></label>
                                                        {renderCustomer()}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            sys.configuration.customer.spokenLanguages.length > 0 || sys.configuration.customer.communicationLanguages.length > 0 ?
                                                <section>
                                                    <div className='row'>
                                                        {
                                                            sys.configuration.customer.spokenLanguages.length > 0 ?
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label htmlFor='update-spoken-language-select'><small>Spoken Language</small></label>
                                                                        <select
                                                                            id='update-spoken-language-select'
                                                                            className='custom-select form-control'
                                                                            value={customer.data.spokenLanguage ? customer.data.spokenLanguage : ''}
                                                                            onChange={handleSpokenLanguageChange}
                                                                        >
                                                                            <option value=''>English</option>
                                                                            {
                                                                                sys.configuration.customer.spokenLanguages.map((l, li) => {
                                                                                    return <option key={`update-customer-spoken-language-${li}`} value={l}>{l}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            sys.configuration.customer.communicationLanguages.length > 0 ?
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label htmlFor='update-communication-language-select'><small>Email / SMS Language</small></label>
                                                                        <select
                                                                            id='update-communication-language-select'
                                                                            className='custom-select form-control'
                                                                            value={customer.data.communicationLanguage ? customer.data.communicationLanguage : ''}
                                                                            onChange={handleCommunicationLanguageChange}
                                                                        >
                                                                            <option value=''>English</option>
                                                                            {
                                                                                sys.configuration.customer.communicationLanguages.map((l, li) => {
                                                                                    return <option key={`update-customer-communication-language-${li}`} value={l.key}>{l.value}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                    </div>
                                                </section> : null
                                        }
                                        <section>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div ref={preferredContactMethodRef} className={'form-group validate'}>
                                                        <label htmlFor='update-preferred-contact-method-select'><small>Preferred Contact Method</small></label>
                                                        <select
                                                            id='update-preferred-contact-method-select'
                                                            className='custom-select form-control'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.preferredContactMethod ? customer.data.preferredContactMethod : ''}
                                                            onChange={handlePreferredContactMethodChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                cache.getReferenceDataOptions('ContactMethodType').map((option, di) => {
                                                                    return <option key={`contact_method_type_${di}`} value={option.key}>{option.value}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-customer-communication-consent-select'><small>Communication Consent</small></label>
                                                        <select
                                                            id='update-customer-communication-consent-select'
                                                            className={'custom-select form-control' + (!!customer.data.communicationUnsubscribed ? ' text-danger fw-500 cursor-not-allowed' : '')}
                                                            disabled={!!customer.data.communicationUnsubscribed}
                                                            value={!fn.isNullOrUndefined(customer.data.communicationConsent) ? customer.data.communicationConsent.toString() : ''}
                                                            onChange={handleCommunicationConsentChange}
                                                        >
                                                            {
                                                                !!customer.data.communicationUnsubscribed ?
                                                                    <option>Unsubscribed</option> :
                                                                    <>
                                                                        <option></option>
                                                                        <option value='true'>Opted-in</option>
                                                                        <option value='false'>Opted-out</option>
                                                                    </>
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label><small>Appointment Reminder</small></label>
                                                        <div className='custom-control custom-switch'>
                                                            <input
                                                                id='update-customer-appointment-reminder'
                                                                type='checkbox'
                                                                name='update-customer-appointment-reminder'
                                                                className='custom-control-input'
                                                                checked={!customer.data.autoReminderDisabled}
                                                                onChange={handleAutoReminderChange}
                                                            />
                                                            <label
                                                                htmlFor='update-customer-appointment-reminder'
                                                                className='custom-control-label'
                                                            >
                                                                {
                                                                    customer.data.autoReminderDisabled ?
                                                                        <span className='fw-500 text-danger'>Manual reminder</span> :
                                                                        <span className='fw-400'>Send automatically</span>
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className={'form-group validate validate-email' + (isEmailRequired() ? ' validate-required' : '')}>
                                                        <label className={isEmailRequired() ? 'required' : ''} htmlFor='update-personal-email-input'><small>Email</small></label>
                                                        <MaskedInput
                                                            id='update-personal-email-input'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            mask={MaskKeys.EMAIL_MASK}
                                                            maxLength='150'
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.emailAddress ? customer.data.emailAddress : ''}
                                                            onChange={handleEmailAddressChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className={hasPrimaryNumberExtension ? 'col-5' : 'col-8'}>
                                                    <div className={'form-group validate validate-phone' + (isPhoneRequired() ? ' validate-required' : '')}>
                                                        <label className={isPhoneRequired() ? 'required' : ''} htmlFor='update-personal-primary-phone-input'><small>Primary Phone</small></label>
                                                        <MaskedInput
                                                            id='update-personal-primary-phone-input'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            mask={MaskKeys.PHONE_MASK}
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.numberOnly ? customer.data.primaryPhoneNumber.numberOnly : ''}
                                                            onChange={handlePrimaryPhoneNumberChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'col-3' + (hasPrimaryNumberExtension ? '' : ' d-none')}>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-primary-phone-ext-input'><small>Ext.</small></label>
                                                        <MaskedInput
                                                            id='update-personal-primary-phone-ext-input'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            mask={MaskKeys.PHONE_EXT_MASK}
                                                            autoComplete='off'
                                                            maxLength={5}
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.extension ? customer.data.primaryPhoneNumber.extension : ''}
                                                            onChange={handlePrimaryPhoneNumberExtensionChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div ref={primaryPhoneTypeRef} className={'form-group' + (isPhoneRequired() ? ' validate validate-required' : '')}>
                                                        <label className={isPhoneRequired() ? 'required' : ''} htmlFor='update-personal-primary-phone-type-select'><small>Type</small></label>
                                                        <select
                                                            id='update-personal-primary-phone-type-select'
                                                            className='custom-select form-control'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.type ? customer.data.primaryPhoneNumber.type : ''}
                                                            onChange={handlePrimaryPhoneNumberTypeChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                cache.getReferenceDataOptions('PhoneType').map((option, di) => {
                                                                    return <option key={`phone_type_${di}`} value={option.key}>{option.value}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                customer.data.primaryPhoneNumber && customer.data.primaryPhoneNumber.type !== 'Mobile' ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='custom-control custom-checkbox ml-1 mt-2'>
                                                                <input
                                                                    id='update-personal-primary-phone-ext-check'
                                                                    type='checkbox'
                                                                    name='updatePrimaryPhoneExtensionCheck'
                                                                    className='custom-control-input'
                                                                    disabled={!ch.checkCanUpdate(customer.data)}
                                                                    checked={hasPrimaryNumberExtension}
                                                                    onChange={handlePrimaryPhoneNumberHasExtensionChange}
                                                                />
                                                                <label
                                                                    htmlFor='update-personal-primary-phone-ext-check'
                                                                    className='custom-control-label d-block'
                                                                >
                                                                    Extension
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className={hasSecondaryNumberExtension ? 'col-5' : 'col-8'}>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-secondary-phone-input'><small>Secondary Phone</small></label>
                                                        <MaskedInput
                                                            id='update-personal-secondary-phone-input'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            mask={MaskKeys.PHONE_MASK}
                                                            autoComplete='off'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.number ? customer.data.secondaryPhoneNumber.number : ''}
                                                            onChange={handleSecondaryPhoneNumberChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'col-3' + (hasSecondaryNumberExtension ? '' : ' d-none')}>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-primary-phone-ext-input'><small>Ext.</small></label>
                                                        <MaskedInput
                                                            id='update-personal-primary-phone-ext-input'
                                                            type='text'
                                                            className='form-control'
                                                            spellCheck={false}
                                                            mask={MaskKeys.PHONE_EXT_MASK}
                                                            autoComplete='off'
                                                            maxLength={5}
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.extension ? customer.data.secondaryPhoneNumber.extension : ''}
                                                            onChange={handleSecondaryPhoneNumberExtensionChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className={'form-group' + (customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.number ? ' validate validate-required' : '')}>
                                                        <label htmlFor='update-personal-secondary-phone-type-select'><small>Type</small></label>
                                                        <select
                                                            id='update-personal-secondary-phone-type-select'
                                                            className='custom-select form-control'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            value={customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.type ? customer.data.secondaryPhoneNumber.type : ''}
                                                            onChange={handleSecondaryPhoneNumberTypeChange}
                                                        >
                                                            <option value=''></option>
                                                            {
                                                                cache.getReferenceDataOptions('PhoneType').map((option, di) => {
                                                                    return <option key={`phone_type_${di}`} value={option.key}>{option.value}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                customer.data.secondaryPhoneNumber && customer.data.secondaryPhoneNumber.type !== 'Mobile' ?
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='custom-control custom-checkbox ml-1 mt-2'>
                                                                <input
                                                                    id='update-personal-secondary-phone-ext-check'
                                                                    type='checkbox'
                                                                    name='updateSecondaryPhoneExtensionCheck'
                                                                    className='custom-control-input'
                                                                    disabled={!ch.checkCanUpdate(customer.data)}
                                                                    checked={hasSecondaryNumberExtension}
                                                                    onChange={handleSecondaryPhoneNumberHasExtensionChange}
                                                                />
                                                                <label
                                                                    htmlFor='update-personal-secondary-phone-ext-check'
                                                                    className='custom-control-label d-block'
                                                                >
                                                                    Extension
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                        </section>
                                        <section>
                                            <div className='divider py-2 mb-5' />
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-primary-contact-person-input'><small>Primary Contact Person</small></label>
                                                        <DropdownList
                                                            data={(
                                                                customer.relationships ?
                                                                    [{}, ...customer.relationships.map(r => {
                                                                        r.relatedTo.relationship = r.relationship;
                                                                        return r.relatedTo;
                                                                    })] : []
                                                            )}
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            valueField='id'
                                                            value={customer.data.primaryContactPersonId}
                                                            itemComponent={({ item }) => (
                                                                item && item.id ?
                                                                    <div
                                                                        className='profile-wrapper pb-0'
                                                                    >
                                                                        <div className='profile d-flex align-items-center'>
                                                                            <span
                                                                                className={`profile-image profile-image-md profile-initials rounded-circle d-flex ${ch.getProfileColor(item)} fw-500`}
                                                                                title={item.fullName}
                                                                            >
                                                                                {item.initials}
                                                                            </span>
                                                                        </div>
                                                                        <span className='description'>
                                                                            <span>{item.fullName}{(item.relationship ? <span className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{item.relationship}</span>)</span> : <></>)}</span>
                                                                            {
                                                                                item.emailAddress ? <span className='fs-90 text-info'>{item.emailAddress}</span> : null
                                                                            }
                                                                            {
                                                                                item.phoneNumber ? <span className='fs-90 text-info'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</span> : null
                                                                            }
                                                                        </span>
                                                                    </div> : <div className='profile-wrapper pb-0'>
                                                                        <span className='text-gray-700'>(Clear)</span>
                                                                    </div>
                                                            )}
                                                            valueComponent={({ item }) => (
                                                                item ?
                                                                    <div
                                                                        className='profile-wrapper pb-0'
                                                                    >
                                                                        <div className='profile d-flex align-items-center'>
                                                                            <span
                                                                                className={`profile-image profile-image-md profile-initials rounded-circle d-flex ${ch.getProfileColor(item)} fw-500`}
                                                                                title={item.fullName}
                                                                            >
                                                                                {item.initials}
                                                                            </span>
                                                                        </div>
                                                                        <span className='description'>
                                                                            <span>{item.fullName}{(item.relationship ? <span className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{item.relationship}</span>)</span> : <></>)}</span>
                                                                            {
                                                                                item.emailAddress ? <span className='fs-90 text-info'>{item.emailAddress}</span> : null
                                                                            }
                                                                            {
                                                                                item.phoneNumber ? <span className='fs-90 text-info'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</span> : null
                                                                            }
                                                                        </span>
                                                                    </div> : null
                                                            )}
                                                            onChange={handlePrimaryContactChange}
                                                        />
                                                        <button
                                                            type='button'
                                                            className='btn btn-sm btn-link px-0'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            onClick={handleAddRelationshipClick}
                                                        >
                                                            Add relationship
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='form-group'>
                                                        <label htmlFor='update-personal-primary-contact-person-input'><small>Emergency Contact Person</small></label>
                                                        <DropdownList
                                                            data={(
                                                                customer.relationships ?
                                                                    [{}, ...customer.relationships.map(r => {
                                                                        r.relatedTo.relationship = r.relationship;
                                                                        return r.relatedTo;
                                                                    })] : []
                                                            )}
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            valueField='id'
                                                            value={customer.data.emergencyContactPersonId}
                                                            itemComponent={({ item }) => (
                                                                item && item.id ?
                                                                    <div
                                                                        className='profile-wrapper pb-0'
                                                                    >
                                                                        <div className='profile d-flex align-items-center'>
                                                                            <span
                                                                                className={`profile-image profile-image-md profile-initials rounded-circle d-flex ${ch.getProfileColor(item)} fw-500`}
                                                                                title={item.fullName}
                                                                            >
                                                                                {item.initials}
                                                                            </span>
                                                                        </div>
                                                                        <span className='description'>
                                                                            <span>{item.fullName}{(item.relationship ? <span className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{item.relationship}</span>)</span> : <></>)}</span>
                                                                            {
                                                                                item.emailAddress ? <span className='fs-90 text-info'>{item.emailAddress}</span> : null
                                                                            }
                                                                            {
                                                                                item.phoneNumber ? <span className='fs-90 text-info'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</span> : null
                                                                            }
                                                                        </span>
                                                                    </div> : <div className='profile-wrapper pb-0'>
                                                                        <span className='text-gray-700'>(Clear)</span>
                                                                    </div>
                                                            )}
                                                            valueComponent={({ item }) => (
                                                                item ?
                                                                    <div
                                                                        className='profile-wrapper pb-0'
                                                                    >
                                                                        <div className='profile d-flex align-items-center'>
                                                                            <span
                                                                                className={`profile-image profile-image-md profile-initials rounded-circle d-flex ${ch.getProfileColor(item)} fw-500`}
                                                                                title={item.fullName}
                                                                            >
                                                                                {item.initials}
                                                                            </span>
                                                                        </div>
                                                                        <span className='description'>
                                                                            <span>{item.fullName}{(item.relationship ? <span className='ml-1'>(<span className='fw-500 fs-90 text-success-700'>{item.relationship}</span>)</span> : <></>)}</span>
                                                                            {
                                                                                item.emailAddress ? <span className='fs-90 text-info'>{item.emailAddress}</span> : null
                                                                            }
                                                                            {
                                                                                item.phoneNumber ? <span className='fs-90 text-info'>{sys.getFormattedPhoneNumber(item.phoneNumber)}</span> : null
                                                                            }
                                                                        </span>
                                                                    </div> : null
                                                            )}
                                                            onChange={handleEmergencyContactChange}
                                                        />
                                                        <button
                                                            type='button'
                                                            className='btn btn-sm btn-link px-0'
                                                            disabled={!ch.checkCanUpdate(customer.data)}
                                                            onClick={handleAddRelationshipClick}
                                                        >
                                                            Add relationship
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </FadeIn> : renderQuickDrawerLoading()
                        }
                    </div>
                    <div className='quick-drawer-action pl-3'>
                        <div className='row'>
                            <div className='col-4'>
                                <button
                                    type='button'
                                    className='btn btn-icon'
                                    onClick={handleContactInstructions}
                                    title='Communication Instructions'
                                >
                                    <i className='fal fa-comment-alt-exclamation'></i>
                                    {
                                        customer && customer.data.contactInstructions && customer.data.contactInstructions.length > 0 ?
                                            <span className={`badge badge-icon`}>{customer.data.contactInstructions.length}</span> : null
                                    }
                                </button>
                            </div>
                            <div className='col-8'>
                                <div className='float-right'>
                                    <button
                                        type='button'
                                        className='btn btn-link btn-cancel mr-2'
                                        onClick={handleCancel}
                                    >Cancel</button>
                                    <button
                                        type='submit'
                                        className='btn btn-success'
                                        disabled={!ch.checkCanUpdate(customer.data)}
                                    >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>)
}

export default UpdateContactInformation;