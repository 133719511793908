import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';
import { toJS } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';
import moment from 'moment';

export class CustomerUpdate {
    id = null;
    data = null;
    newAddress = {
        geocode: null,
        streetNumber: null,
        streetName: null,
        unitSuiteType: null,
        unitSuite: null,
        line2: null,
        locality: null,
        sublocality: null,
        postalCode: null,
        region: null,
        regionCode: null,
        country: null,
        countryCode: null,
    };
    newShippingAddress = {
        geocode: null,
        streetNumber: null,
        streetName: null,
        unitSuiteType: null,
        unitSuite: null,
        line2: null,
        locality: null,
        sublocality: null,
        postalCode: null,
        region: null,
        regionCode: null,
        country: null,
        countryCode: null,
    };
    newPrivateInsurance = {
        privateInsuranceProviderId: null,
        primaryPolicyHolderId: null,
        policyHolderName: null,
        memberId: null,
        planNumber: null,
        accountNumber: null,
        policyNumber: null,
        noteHtml: null,
        members: [],
    };
    deactivateReason = null;
    deactivateNotePreview = null;
    deactivateNoteBodyHtml = null;

    patientProfile = null;
    purchases = [];
    appointments = [];
    relationships = [];
    relationshipSuggestions = [];
    privateInsurances = [];

    selectedPrivateInsuranceId = null;

    hasPersonalUnsavedChanges = false;
    hasAddressUnsavedChanges = false;
    hasContactUnsavedChanges = false;
    hasPatientUnsavedChanges = false;
    hasRelationshipUnsavedChanges = false;
    hasRelationshipSuggestionUnsavedChanges = false;
    hasPrivateInsuranceUnsavedChanges = false;

    isPatientProfileReady = false;
    isPurchasesReady = false;
    isAppointmentsReady = false;
    isRelationshipsReady = false;
    isRelationshipSuggestionsReady = false;
    isPrivateInsuranceReady = false;

    isLoading = false;
    isReady = false;
    isDataReady = false;

    cancelAddressInformationUpdate = null;
    cancelContactInformationUpdate = null;
    cancelCustomer = null;
    cancelPrivateInsurance = null;
    cancelPrivateInsuranceCreate = null;
    cancelPrivateInsuranceUpdate = null;
    cancelPrivateInsuranceDelete = null;
    cancelPatientInformationUpdate = null;
    cancelPatientProfile = null;
    cancelPersonalInformationUpdate = null;
    cancelRelationships = null;
    cancelRelationshipsUpdate = null;
    cancelRelationshipSuggestions = null;
    cancelRelationshipSuggestionsUpdate = null;

    initialize = (id) => {
        const that = this;

        this.clear();
        this.id = id;

        return new Promise((resolve, reject) => {
            that.refresh()
                .then(() => {
                    that.isDataReady = true;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isLoading = false;
                    that.isReady = true;
                })
        })
    }

    refresh = () => {
        const that = this;
        return new Promise((resolve, reject) => {
            api.Customers.get(
                this.id,
                (c) => { that.cancelCustomer = c }
            )
                .then(({ data }) => {
                    that.data = data;
                    if (data.address) {
                        that.newAddress = data.address;
                    }
                    if (data.shippingAddress) {
                        that.newShippingAddress = data.shippingAddress;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    setPatientProfile = (patientProfile) => {
        this.patientProfile = toJS(patientProfile);
        this.isPatientProfileReady = true;
    }

    loadPatientProfile = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.data.patientProfileId) {
                api.PatientProfiles.get(
                    that.data.patientProfileId,
                    (c) => { that.cancelPatientProfile = c }
                )
                    .then(({ data }) => {
                        that.patientProfile = data;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isPatientProfileReady = true;
                    })
            } else {
                that.patientProfile = {};
                resolve();
                that.isPatientProfileReady = true;
            }
        })
    }

    setRelationships = (relationships) => {
        this.relationships = toJS(relationships);
        this.isRelationshipsReady = true;
    }

    loadRelationships = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            api.CustomerRelationships.get(
                that.id,
                false,
                (c) => { that.cancelRelationships = c }
            )
                .then(({ data }) => {
                    if (data && data.length > 0) {
                        that.relationships = data;
                    } else {
                        that.relationships.clear();
                    }
                    that.isRelationshipsReady = true;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    loadRelationshipSuggestions = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            api.CustomerRelationships.suggestions(
                that.id,
                false,
                (c) => { that.cancelRelationshipSuggestions = c }
            )
                .then(({ data }) => {
                    if (data && data.length > 0) {
                        that.relationshipSuggestions = data;
                    } else {
                        that.relationshipSuggestions.clear();
                    }
                    that.isRelationshipSuggestionsReady = true;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    addRelationship = (suggestion) => {
        const that = this;

        return new Promise((resolve, reject) => {
            api.CustomerRelationships.create(
                {
                    party1Id: that.data.id,
                    party2Id: suggestion.id,
                    relationshipType: suggestion.relationship,
                },
                (c) => { that.cancelRelationshipSuggestions = c }
            )
                .then(() => {
                    const index = that.relationshipSuggestions.findIndex(s => s.id === suggestion.id);
                    if (index > -1) {
                        that.relationshipSuggestions.splice(index, 1);
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    removeRelationshipSuggestion = (suggestion) => {
        const that = this;

        return new Promise((resolve, reject) => {
            api.CustomerRelationships.delete(
                {
                    party1Id: that.data.id,
                    party2Id: suggestion.id,
                },
                (c) => { that.cancelRelationshipSuggestions = c }
            )
                .then(() => {
                    const index = that.relationshipSuggestions.findIndex(s => s.id === suggestion.id);
                    that.relationshipSuggestions.splice(index, 1);
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    loadInsurance = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            api.CustomerPrivateInsurances.search(
                {
                    parameters: [{
                        field: 'CustomerId',
                        value: that.id,
                    }],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    }],
                    loadProperties: true,
                    includeTotalCount: false,
                },
                (c) => { that.cancelPrivateInsurance = c }
            )
                .then(({ data }) => {
                    that.privateInsurances.clear();

                    if (data && data.result && data.result.length > 0) {
                        that.privateInsurances = data.result;
                    }

                    that.isPrivateInsuranceReady = true;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    updatePersonalInformation = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.hasPersonalUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.Customers.update(
                    that.data.id,
                    {
                        personalInformation: {
                            color: that.data.color,
                            salutation: that.data.salutation,
                            firstName: that.data.firstName,
                            preferredFirstName: that.data.preferredFirstName,
                            middleName: that.data.middleName,
                            lastName: that.data.lastName,
                            suffix: that.data.suffix,
                            sex: that.data.sex,
                            gender: that.data.gender,
                            genderOther: that.data.genderOther,
                            pronoun: that.data.pronoun,
                            pronounOther: that.data.pronounOther,
                            dateOfBirth: that.data.dateOfBirth,
                        }
                    },
                    (c) => { that.cancelPersonalInformationUpdate = c }
                )
                    .then(() => {
                        that.hasPersonalUnsavedChanges = false;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        this.isSaving = false;
                    })
            } else {
                resolve();
            }
        })
    }

    updateAddressInformation = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.hasAddressUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.Customers.update(
                    that.data.id,
                    {
                        addressInformation: {
                            isShippingAddressSameAsHome: that.data.isShippingAddressSameAsHome,
                            existingAddress: that.data.address,
                            newAddress: that.newAddress,
                            existingShippingAddress: that.data.shippingAddress,
                            newShippingAddress: that.newShippingAddress,
                        }
                    },
                    (c) => { that.cancelAddressInformationUpdate = c }
                )
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                        that.hasAddressUnsavedChanges = false;
                    })
            } else {
                resolve();
            }
        })
    }

    updateContactInformation = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.hasContactUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.Customers.update(
                    that.data.id,
                    {
                        contactInformation: {
                            emailAddress: that.data.emailAddress,
                            primaryPhoneNumber: that.data.primaryPhoneNumber,
                            secondaryPhoneNumber: that.data.secondaryPhoneNumber,
                            preferredContactMethodType: that.data.preferredContactMethod,
                            communicationConsent: that.data.communicationConsent,
                            autoReminderDisabled: that.data.autoReminderDisabled,
                            primaryContactPersonId: that.data.primaryContactPersonId,
                            emergencyContactPersonId: that.data.emergencyContactPersonId,
                            spokenLanguage: that.data.spokenLanguage,
                            communicationLanguage: that.data.communicationLanguage,
                        }
                    },
                    (c) => { that.cancelContactInformationUpdate = c }
                )
                    .then(() => {
                        that.hasContactUnsavedChanges = false;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                resolve();
            }
        })
    }

    updatePatientInformation = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.hasPatientUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.Customers.update(
                    that.data.id,
                    {
                        patientInformation: {
                            healthCardNumber: that.patientProfile.healthCardNumber ? that.patientProfile.healthCardNumber.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() : null,
                            healthCardExpiryDate: that.patientProfile.healthCardNumber ? that.patientProfile.healthCardExpiryDate : null,
                            recallFrequencyInMonth: that.patientProfile.recallFrequencyInMonth,
                            doNotRecall: that.patientProfile.doNotRecall,
                            isVerified: that.patientProfile.isVerified,
                            verificationResponse: that.patientProfile.verificationResponse,
                            familyDoctorId: that.patientProfile.familyDoctorId,
                            familyDoctorClinicId: that.patientProfile.familyDoctorClinicId,
                        }
                    },
                    (c) => { that.cancelPatientInformationUpdate = c }
                )
                    .then(() => {
                        that.hasPatientUnsavedChanges = false;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                resolve();
            }
        })
    }

    updateRelationshipInformation = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.hasRelationshipUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.Customers.update(
                    that.data.id,
                    {
                        relationshipInformation: {
                            relationships: that.relationships.map(r => { return { relatedToId: r.relatedTo.id, relationship: r.relationship } })
                        }
                    },
                    (c) => { that.cancelRelationshipsUpdate = c }
                )
                    .then(() => {
                        that.hasRelationshipUnsavedChanges = false;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                resolve();
            }
        })
    }

    addPrivateInsuranceInformation = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (!!notify) {
                that.isSaving = true;
            }

            api.CustomerPrivateInsurances.create(
                that.id, that.newPrivateInsurance,
                (c) => { that.cancelPrivateInsuranceCreate = c }
            )
                .then(() => {
                    that.hasPrivateInsuranceUnsavedChanges = false;
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    updatePrivateInsuranceInformation = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.hasPrivateInsuranceUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.CustomerPrivateInsurances.update(
                    that.data.id, that.selectedPrivateInsurance,
                    (c) => { that.cancelPrivateInsuranceUpdate = c }
                )
                    .then(() => {
                        that.hasPrivateInsuranceUnsavedChanges = false;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                resolve();
            }
        })
    }

    updatePrivateInsurancesDisplayOrder = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.hasPrivateInsuranceUnsavedChanges) {
                if (!!notify) {
                    that.isSaving = true;
                }

                const option = {
                    items: that.privateInsurances.map(i => {
                        return {
                            privateInsurancePolicyId: i.privateInsurancePolicyId, displayOrder: i.displayOrder
                        }
                    })
                };

                api.CustomerPrivateInsurances.reorder(that.data.id, option)
                    .then(() => {
                        that.hasPrivateInsuranceUnsavedChanges = false;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            } else {
                resolve();
            }
        })
    }

    deletePrivateInsuranceInformation = (method, notify) => {
        const that = this;

        if (method) {
            return new Promise((resolve, reject) => {
                if (!!notify) {
                    that.isSaving = true;
                }

                api.CustomerPrivateInsurances.delete(
                    that.id,
                    {
                        privateInsurancePolicyId: that.selectedPrivateInsuranceId,
                        action: method,
                    },
                    (c) => { that.cancelPrivateInsuranceDelete = c }
                )
                    .then(() => {
                        that.hasPrivateInsuranceUnsavedChanges = false;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isSaving = false;
                    })
            })
        } else {
            return Promise.resolve();
        }
    }

    copyAddressToRelationship = (relationship, notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            const index = that.relationships.findIndex(r => r.id === relationship.id);
            const newAddress = that.data.address ? JSON.parse(JSON.stringify(toJS(that.data.address))) : null;

            if (newAddress) {
                newAddress.id = fn.newId('adr_');
            }

            if (!!notify) {
                that.isSaving = true;
            }

            api.Customers.update(
                relationship.relatedTo.id,
                {
                    addressInformation: {
                        isShippingAddressSameAsHome: relationship.relatedTo.isShippingAddressSameAsHome,
                        existingAddress: relationship.relatedTo.address,
                        newAddress: newAddress,
                        existingShippingAddress: that.data.shippingAddress,
                        newShippingAddress: relationship.relatedTo.isShippingAddressSameAsHome === true ? newAddress : that.data.shippingAddress,
                    }
                },
                (c) => { that.cancelAddressInformationUpdate = c }
            )
                .then(() => {
                    if (index >= -1) {
                        that.relationships[index].relatedTo.address = newAddress;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    copyEmailToRelationship = (relationship, notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            const index = that.relationships.findIndex(r => r.id === relationship.id);
            const newEmail = that.data.emailAddress ? that.data.emailAddress : null;

            if (!!notify) {
                that.isSaving = true;
            }

            api.Customers.update(
                relationship.relatedTo.id,
                {
                    contactInformation: {
                        emailAddress: newEmail,
                        primaryPhoneNumber: relationship.relatedTo.primaryPhoneNumber,
                        secondaryPhoneNumber: relationship.relatedTo.secondaryPhoneNumber,
                        preferredContactMethodType: relationship.relatedTo.preferredContactMethod,
                        communicationConsent: relationship.relatedTo.communicationConsent,
                        primaryContactPersonId: relationship.relatedTo.primaryContactPersonId,
                        emergencyContactPersonId: relationship.relatedTo.emergencyContactPersonId,
                    }
                },
                (c) => { that.cancelContactInformationUpdate = c }
            )
                .then(() => {
                    if (index >= -1) {
                        that.relationships[index].relatedTo.emailAddress = newEmail;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    copyPhoneNumberToRelationship = (relationship, notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            const index = that.relationships.findIndex(r => r.id === relationship.id);
            const newPhone = that.data.primaryPhoneNumber ? JSON.parse(JSON.stringify(toJS(that.data.primaryPhoneNumber))) : null;

            if (newPhone) {
                newPhone.id = fn.newId('ph_');
            }

            if (!!notify) {
                that.isSaving = true;
            }

            api.Customers.update(
                relationship.relatedTo.id,
                {
                    contactInformation: {
                        emailAddress: relationship.relatedTo.emailAddress,
                        primaryPhoneNumber: newPhone,
                        secondaryPhoneNumber: relationship.relatedTo.secondaryPhoneNumber,
                        preferredContactMethodType: relationship.relatedTo.preferredContactMethod,
                        communicationConsent: relationship.relatedTo.communicationConsent,
                        primaryContactPersonId: relationship.relatedTo.primaryContactPersonId,
                        emergencyContactPersonId: relationship.relatedTo.emergencyContactPersonId,
                    }
                },
                (c) => { that.cancelContactInformationUpdate = c }
            )
                .then(() => {
                    if (index >= -1) {
                        that.relationships[index].relatedTo.phoneNumber = newPhone.number;
                        that.relationships[index].relatedTo.primaryPhoneNumber = newPhone;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    copyFamilyDoctorToRelationship = (relationship, notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            const index = that.relationships.findIndex(r => r.id === relationship.id);

            if (!!notify) {
                that.isSaving = true;
            }

            api.Customers.update(
                relationship.relatedTo.id,
                {
                    patientInformation: {
                        healthCardNumber: relationship.relatedTo.patientProfile && relationship.relatedTo.patientProfile.healthCardNumber ? relationship.relatedTo.patientProfile.healthCardNumber.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() : null,
                        healthCardExpiryDate: relationship.relatedTo.patientProfile && relationship.relatedTo.patientProfile.healthCardNumber ? relationship.relatedTo.patientProfile.healthCardExpiryDate : null,
                        recallFrequencyInMonth: relationship.relatedTo.patientProfile && relationship.relatedTo.patientProfile.recallFrequencyInMonth ? relationship.relatedTo.patientProfile.recallFrequencyInMonth : null,
                        doNotRecall: relationship.relatedTo.patientProfile && relationship.relatedTo.patientProfile.doNotRecall ? relationship.relatedTo.patientProfile.doNotRecall : false,
                        isVerified: relationship.relatedTo.patientProfile && relationship.relatedTo.patientProfile.isVerified ? relationship.relatedTo.patientProfile.isVerified : false,
                        verificationResponse: relationship.relatedTo.patientProfile && relationship.relatedTo.patientProfile.verificationResponse ? relationship.relatedTo.patientProfile.verificationResponse : null,
                        familyDoctorId: that.patientProfile ? that.patientProfile.familyDoctorId : null,
                        familyDoctorClinicId: that.patientProfile ? that.patientProfile.familyDoctorClinicId : null,
                    }
                },
                (c) => { that.cancelPatientInformationUpdate = c }
            )
                .then(() => {
                    if (index >= -1) {
                        if (!that.relationships[index].relatedTo.patientProfile) {
                            that.relationships[index].relatedTo.patientProfile = {};
                        }

                        that.relationships[index].relatedTo.patientProfile.familyDoctorId = that.patientProfile ? that.patientProfile.familyDoctorId : null;
                        that.relationships[index].relatedTo.patientProfile.familyDoctor = that.patientProfile ? that.patientProfile.familyDoctor : null;
                        that.relationships[index].relatedTo.patientProfile.familyDoctorClinicId = that.patientProfile ? that.patientProfile.familyDoctorClinicId : null;
                        that.relationships[index].relatedTo.patientProfile.familyDoctorClinic = that.patientProfile ? that.patientProfile.familyDoctorClinic : null;
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    reactivate = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (!!notify) {
                that.isSaving = true;
            }

            api.Customers.reactivate(that.id, {
                note: that.deactivateNotePreview,
                noteHtml: that.deactivateNoteBodyHtml,
                reactivatedDateUtc: moment(),
            })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    deactivate = (notify) => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (!!that.deactivateReason) {
                // Use CustomerMergeStore instead if the reason is "Duplicate".
                if (that.deactivateReason.toLowerCase() !== 'duplicate') {
                    if (!!notify) {
                        that.isSaving = true;
                    }

                    api.Customers.deactivate(that.id, {
                        reason: that.deactivateReason,
                        note: that.deactivateNotePreview,
                        noteHtml: that.deactivateNoteBodyHtml,
                        deactivatedDateUtc: moment(),
                    })
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        })
                        .finally(() => {
                            that.isSaving = false;
                        })
                }
                else {
                    // Use CustomerMergeStore instead if the reason is "Duplicate".
                    reject();
                }
            }
        })
    }

    get selectedPrivateInsurance() {
        if (this.selectedPrivateInsuranceIndex >= 0) {
            return this.privateInsurances[this.selectedPrivateInsuranceIndex];
        }

        return null;
    }

    get selectedPrivateInsuranceIndex() {
        if (this.privateInsurances && this.privateInsurances.length > 0 && this.selectedPrivateInsuranceId) {
            return this.privateInsurances.findIndex(i => i.privateInsurancePolicyId === this.selectedPrivateInsuranceId);
        }

        return null;
    }

    get hasUnsavedChanges() {
        return this.hasPersonalUnsavedChanges ||
            this.hasAddressUnsavedChanges ||
            this.hasContactUnsavedChanges ||
            this.hasPatientUnsavedChanges ||
            this.hasRelationshipUnsavedChanges ||
            this.hasRelationshipSuggestionUnsavedChanges ||
            this.hasPrivateInsuranceUnsavedChanges;
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.patientProfile = null;
        this.purchases.clear();
        this.appointments.clear();
        this.relationships.clear();
        this.privateInsurances.clear();
        this.isLoading = false;
        this.isReady = false;
        this.isDataReady = false;
        this.isPatientProfileReady = false;
        this.isPurchasesReady = false;
        this.isAppointmentsReady = false;
        this.isRelationshipsReady = false;
        this.isRelationshipSuggestionsReady = false;
        this.isPrivateInsuranceReady = false;
        this.selectedPrivateInsuranceId = null;
        this.clearNewAddress();
        this.clearNewShippingAddress();
        this.clearNewPrivateInsurance();
        this.clearDeactivateSettings();
        this.clearUnsavedChanges();

        if (fn.isFunction(this.cancelAddressInformationUpdate)) {
            this.cancelAddressInformationUpdate();
            this.cancelAddressInformationUpdate = null;
        }

        if (fn.isFunction(this.cancelContactInformationUpdate)) {
            this.cancelContactInformationUpdate();
            this.cancelContactInformationUpdate = null;
        }

        if (fn.isFunction(this.cancelCustomer)) {
            this.cancelCustomer();
            this.cancelCustomer = null;
        }

        if (fn.isFunction(this.cancelPrivateInsurance)) {
            this.cancelPrivateInsurance();
            this.cancelPrivateInsurance = null;
        }

        if (fn.isFunction(this.cancelPrivateInsuranceCreate)) {
            this.cancelPrivateInsuranceCreate();
            this.cancelPrivateInsuranceCreate = null;
        }

        if (fn.isFunction(this.cancelPrivateInsuranceUpdate)) {
            this.cancelPrivateInsuranceUpdate();
            this.cancelPrivateInsuranceUpdate = null;
        }

        if (fn.isFunction(this.cancelPrivateInsuranceDelete)) {
            this.cancelPrivateInsuranceDelete();
            this.cancelPrivateInsuranceDelete = null;
        }

        if (fn.isFunction(this.cancelPatientInformationUpdate)) {
            this.cancelPatientInformationUpdate();
            this.cancelPatientInformationUpdate = null;
        }

        if (fn.isFunction(this.cancelPatientProfile)) {
            this.cancelPatientProfile();
            this.cancelPatientProfile = null;
        }

        if (fn.isFunction(this.cancelPersonalInformationUpdate)) {
            this.cancelPersonalInformationUpdate();
            this.cancelPersonalInformationUpdate = null;
        }

        if (fn.isFunction(this.cancelRelationships)) {
            this.cancelRelationships();
            this.cancelRelationships = null;
        }

        if (fn.isFunction(this.cancelRelationshipsUpdate)) {
            this.cancelRelationshipsUpdate();
            this.cancelRelationshipsUpdate = null;
        }

        if (fn.isFunction(this.cancelRelationshipSuggestions)) {
            this.cancelRelationshipSuggestions();
            this.cancelRelationshipSuggestions = null;
        }

        if (fn.isFunction(this.cancelRelationshipSuggestionsUpdate)) {
            this.cancelRelationshipSuggestionsUpdate();
            this.cancelRelationshipSuggestionsUpdate = null;
        }
    }

    clearNewAddress = () => {
        this.newAddress = {
            geocode: null,
            streetNumber: null,
            streetName: null,
            unitSuiteType: null,
            unitSuite: null,
            line2: null,
            locality: null,
            sublocality: null,
            postalCode: null,
            region: null,
            regionCode: null,
            country: null,
            countryCode: null
        };
    }

    clearNewShippingAddress = () => {
        this.newShippingAddress = {
            geocode: null,
            streetNumber: null,
            streetName: null,
            unitSuiteType: null,
            unitSuite: null,
            line2: null,
            locality: null,
            sublocality: null,
            postalCode: null,
            region: null,
            regionCode: null,
            country: null,
            countryCode: null,
        }
    }

    clearNewPrivateInsurance = () => {
        this.newPrivateInsurance = {
            privateInsuranceProviderId: null,
            primaryPolicyHolderId: null,
            policyHolderName: null,
            memberId: null,
            planNumber: null,
            accountNumber: null,
            policyNumber: null,
            noteHtml: null,
            members: [],
        }
    }

    clearDeactivateSettings = () => {
        this.deactivateReason = null;
        this.deactivateNotePreview = null;
        this.deactivateNoteBodyHtml = null;
    }

    clearUnsavedChanges = () => {
        this.hasPersonalUnsavedChanges = false;
        this.hasAddressUnsavedChanges = false;
        this.hasContactUnsavedChanges = false;
        this.hasPatientUnsavedChanges = false;
        this.hasPrivateInsuranceUnsavedChanges = false;
    }
}

decorate(CustomerUpdate, {
    id: observable,
    data: observable,
    loadProperties: observable,
    patientProfile: observable,
    purchases: observable.deep,
    appointments: observable.deep,
    relationships: observable.deep,
    relationshipSuggestions: observable.deep,
    privateInsurances: observable,
    newAddress: observable.deep,
    newShippingAddress: observable.deep,
    newPrivateInsurance: observable.deep,
    deactivateReason: observable,
    deactivateNotePreview: observable,
    deactivateNoteBodyHtml: observable,
    hasPersonalUnsavedChanges: observable,
    hasAddressUnsavedChanges: observable,
    hasContactUnsavedChanges: observable,
    hasPatientUnsavedChanges: observable,
    hasRelationshipUnsavedChanges: observable,
    hasRelationshipSuggestionUnsavedChanges: observable,
    hasPrivateInsuranceUnsavedChanges: observable,
    isLoading: observable,
    isReady: observable,
    isDataReady: observable,
    isPatientProfileReady: observable,
    isPurchasesReady: observable,
    isAppointmentsReady: observable,
    isRelationshipsReady: observable,
    isRelationshipSuggestionsReady: observable,
    isPrivateInsuranceReady: observable,
    selectedPrivateInsuranceId: observable,
    initialize: action,
    setPatientProfile: action,
    loadPatientProfile: action,
    setRelationships: action,
    loadRelationships: action,
    loadRelationshipSuggestions: action,
    loadInsurance: action,
    updatePersonalInformation: action,
    updateAddressInformation: action,
    addPrivateInsuranceInformation: action,
    updatePrivateInsuranceInformation: action,
    updatePrivateInsurancesDisplayOrder: action,
    deletePrivateInsuranceInformation: action,
    copyAddressToRelationship: action,
    copyEmailToRelationship: action,
    copyPhoneNumberToRelationship: action,
    copyFamilyDoctorToRelationship: action,
    reactivate: action,
    deactivate: action,
    clear: action,
    clearNewAddress: action,
    clearNewShippingAddress: action,
    clearNewPrivateInsurance: action,
    clearDeactivateSettings: action,
    clearUnsavedChanges: action,
    selectedPrivateInsurance: computed,
    selectedPrivateInsuranceIndex: computed,
    hasUnsavedChanges: computed,
})

export default createContext(new CustomerUpdate());