import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import MaskedInput from 'react-text-mask'
import { GlobalHotKeys } from 'react-hotkeys';
import { DateTimePicker } from 'react-widgets'
import { toast } from 'react-toastify';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';
import RichTextEditor from '../../_shared/RichTextEditor';

import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';
import CacheStore from '../../../../stores/CacheStore';

import * as ErrorMessages from '../../../../constants/errorMessages';
import * as MaskKeys from '../../../../constants/maskKeys';
import * as fn from '../../../../utilities/_functions';
import * as oh from '../../../../utilities/operationHelper';
import * as ph from '../../../../utilities/personHelper';
import * as ah from '../../../../utilities/addressHelper';
import * as ch from '../../../../utilities/customerHelper';
import * as ih from '../../../../utilities/invoiceHelper';
import * as bh from '../../../../utilities/badgeHelper';

import './RefundPayment.scss';

function RefundPayment(props) {
    const isMounted = useRef(true);
    const validateRef = useRef(null);
    const focusTimer = useRef(null);
    const purchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);
    const cache = useContext(CacheStore);

    useEffect(() => {
        if (props.extraProps) {
            purchase.refundPaymentId = props.extraProps.id ? props.extraProps.id : null;
            purchase.refundAmount = props.extraProps.amount ? props.extraProps.amount : null;
            purchase.refundMethod = cache.referenceData && props.extraProps.paymentMethod && cache.getReferenceDataOptions('RefundMethodType').some(r => r.key === props.extraProps.paymentMethod) ?
                props.extraProps.paymentMethod : null;
        }

        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)

        purchase.refundDate = moment().startOf('day');

        return () => {
            isMounted.current = false;

            purchase.clearRefund();
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handleCancel = () => {
        if (fn.isFunction(props.onCancel)) {
            if (purchase.hasUnsavedChanges) {
                if (window.confirm(ErrorMessages.DISCARD_CHANGES)) {
                    props.onCancel();
                }
            } else {
                props.onCancel();
            }
        }
    }

    const handleAmountChange = event => {
        const amount = event.target.value ? fn.parseCurrency(event.target.value) : null;
        purchase.refundAmount = amount;
        purchase.hasUnsavedChanges = true;
    }

    const handleRefundDateChange = value => {
        const date = moment(value);
        purchase.refundDate = date;
        purchase.hasUnsavedChanges = true;
    }

    const handleRefundMethodChange = event => {
        const refundMethod = event.target.value;
        purchase.refundMethod = refundMethod;
        purchase.hasUnsavedChanges = true;
    }

    const handleReasonChange = (content, delta, source, editor) => {
        const note = editor.getText().replace(/(\r\n|\n|\r)/g, ' ').trim();
        const noteHtml = (content === '<p><br></p>') ? null : content;

        purchase.refundReason = note;
        purchase.refundReasonHtml = noteHtml;
        purchase.hasUnsavedChanges = true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        if (fn.validateForm(validateRef.current) && getRemainingBalance() <= purchase.data.total && purchase.refundAmount > 0.0) {
            purchase.refundPayment(true)
                .then(() => {
                    if (isMounted.current) {
                        if (props.onSuccess && fn.isFunction(props.onSuccess)) {
                            props.onSuccess(event, { updated: true });
                        }
                    }
                })
        }
        else if (purchase.refundAmount <= 0.0) {
            toast.error(() => <p>Refund amount must be greater than $0.00 to proceed.</p>, { position: 'top-center' });
        }
        else {
            toast.error(() => <p>Remaining balance must be less than invoice total to proceed.</p>, { position: 'top-center' });
        }
    }

    const getRemainingBalance = () => {
        if (purchase && purchase.data) {
            return (purchase.data.remainingBalance + (purchase.refundAmount ? purchase.refundAmount : 0));
        }
        return 0;
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    handleCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form ref={validateRef} onSubmit={handleSubmit}>
            <Observer>{() =>
                <fieldset disabled={purchase.isSaving}>
                    <div className='refund-payment-container quick-drawer'>
                        <QuickDrawerHeader
                            drawer={props.drawer}
                            icon={oh.getIcon('purchase', 'refund-payment')}
                            action='Purchase'
                            category='Refund Payment'
                            className='delete'
                            onCancel={handleCancel}
                        />
                        <div className='quick-drawer-body'>
                            <Observer>{() =>
                                <>
                                    {
                                        purchase.data ?
                                            <FadeIn>
                                                <div className='body-content'>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <h3 className='purchase-title'>Invoice Detail</h3>
                                                                <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className='text-right'>
                                                                    {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <Observer>{() =>
                                                        <>
                                                            {
                                                                purchase.data.customer ?
                                                                    <section className='customer'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <label><small>Billing Information</small></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                <Observer>{() =>
                                                                                    <div
                                                                                        className='profile-wrapper'
                                                                                    >
                                                                                        <div className='profile'>
                                                                                            <span
                                                                                                className={`profile-image profile-initials rounded-circle d-flex text-white ${ch.getProfileColor(purchase.data.customer)} fw-500`}
                                                                                                title={purchase.data.customer.fullName}
                                                                                            >
                                                                                                {purchase.data.customer.initials}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className='description flex-1'>
                                                                                            <div className='name'>{ph.getFullName(purchase.data.customer, true)}
                                                                                                {
                                                                                                    purchase.data.customer.dateOfBirth || purchase.data.customer.sex || purchase.data.customer.gender || purchase.data.customer.pronoun ?
                                                                                                        <small className='text-nowrap ml-2'>({`${ph.getAge(purchase.data.customer.dateOfBirth)} ${ph.getSexGenderPronounDisplay(purchase.data.customer)}`.trim()})</small> : null
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                purchase.data.billingAddress ?
                                                                                                    <div className='info'>{ah.getAddressHtml(purchase.data.billingAddress)}</div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerEmail ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`mailto:${purchase.data.customerEmail}`}
                                                                                                        >{purchase.data.customerEmail}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                            {
                                                                                                purchase.data.customerPhoneNumber ?
                                                                                                    <div className='info'>
                                                                                                        <a
                                                                                                            href={`tel:${purchase.data.customerPhoneNumber}`}
                                                                                                        >{purchase.data.customerPhoneNumber}
                                                                                                        </a>
                                                                                                    </div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }</Observer>
                                                                            </div>
                                                                        </div>
                                                                    </section> : null
                                                            }
                                                        </>
                                                    }</Observer>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-4'>
                                                                <label><small>Invoice Total</small></label>
                                                                <Observer>{() =>
                                                                    <strong className='d-block fs-xl'>{fn.formatCurrency(purchase.data.total)}</strong>
                                                                }</Observer>
                                                            </div>
                                                            <div className='col-8'>
                                                                <label><small className='text-danger'>New Remaining Balance</small></label>
                                                                <Observer>{() =>
                                                                    <strong className={'d-block text-danger fs-xl' + (getRemainingBalance() < 0 ? ' text-danger' : '')}>{fn.formatCurrency(getRemainingBalance())}</strong>
                                                                }</Observer>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-7'>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label htmlFor='refund-payment-amount' className='required'><small>Amount</small></label>
                                                                    <Observer>{() =>
                                                                        <MaskedInput
                                                                            id='refund-payment-amount'
                                                                            className='form-control currency-input'
                                                                            mask={MaskKeys.CURRENCY_POSITIVE_MASK}
                                                                            autoComplete='off'
                                                                            disabled={!!purchase.refundPaymentId}
                                                                            value={purchase.refundAmount ? purchase.refundAmount : ''}
                                                                            onChange={handleAmountChange}
                                                                        />
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                            <div className='col-4'>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label htmlFor='refund-payment-refunded-by' className='required'><small>Refunded by</small></label>
                                                                    <Observer>{() =>
                                                                        <select
                                                                            id='refund-payment-refunded-by'
                                                                            className='custom-select form-control'
                                                                            value={purchase.refundMethod ? purchase.refundMethod : ''}
                                                                            onChange={handleRefundMethodChange}
                                                                        >
                                                                            <option value=''></option>
                                                                            {
                                                                                cache.getReferenceDataOptions('RefundMethodType').map((option, di) => {
                                                                                    return <option key={`refund_method_type_${di}`} value={option.key}>{ih.getRefundMethodDescription(option)}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-7'>
                                                                <div className='form-group mb-0 validate validate-required'>
                                                                    <label className='required'><small>Refunded on (YYYY-MM-DD)</small></label>
                                                                    <Observer>{() =>
                                                                        <DateTimePicker
                                                                            dateFormat={dt => String(dt.getDate())}
                                                                            dayFormat={day => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.getDay()]}
                                                                            format={'YYYY-MM-DD'}
                                                                            views={['month', 'year']}
                                                                            footer={false}
                                                                            date={true}
                                                                            time={false}
                                                                            min={moment.utc(purchase.createdDateUtc).local().add(-1, 'days').toDate()}
                                                                            max={moment().toDate()}
                                                                            value={purchase.refundDate ? moment(purchase.refundDate).toDate() : null}
                                                                            onChange={handleRefundDateChange}
                                                                        />
                                                                    }</Observer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group validate validate-required'>
                                                                    <label className='required'><small>Refund Reason</small></label>
                                                                    <div className='input-group'>
                                                                        <Observer>{() =>
                                                                            <RichTextEditor
                                                                                mode='none'
                                                                                disableTab={true}
                                                                                value={purchase.refundReasonHtml}
                                                                                onChange={handleReasonChange}
                                                                            />
                                                                        }</Observer>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </FadeIn> : renderQuickDrawerLoading()
                                    }
                                </>
                            }</Observer>
                        </div>
                        <div className='quick-drawer-action pl-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='float-right'>
                                        <button
                                            type='button'
                                            className='btn btn-link btn-cancel mr-2'
                                            onClick={handleCancel}
                                        >Cancel</button>
                                        <button
                                            type='submit'
                                            className='btn btn-danger'
                                        >Refund</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            }</Observer>
        </form>
    </>
}

export default RefundPayment;