import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import moment from 'moment';

import useSignalR from '../../hooks/useSignalR';

import * as rts from '../../../constants/routes';
import * as fn from '../../../utilities/_functions';

import api from '../../../api';

function NavbarCommunication() {
    const isMounted = useRef(true);
    const isPageVisible = useRef(null);
    const cancelLoadData = useRef(null);
    const signalR = useSignalR();
    const navigate = useNavigate();
    const pageVisibility = usePageVisibility();
    const [scheduledCommunicationCount, setScheduledCommunicationCount] = useState(0);
    const [failedCommunicationCount, setFailedCommunicationCount] = useState(0);
    const [forceReload, setForceReload] = useState(false);

    useEffect(() => {
        isPageVisible.current = pageVisibility;

        loadData();

        signalR.on('Communication', async (updated) => {
            if (updated) {
                if (isPageVisible.current) {
                    loadData();
                } else if (isMounted.current) {
                    setForceReload(true);
                }
            }
        });

        return () => {
            isMounted.current = false;
            if (cancelLoadData.current != null && fn.isFunction(cancelLoadData.current)) {
                cancelLoadData.current();
                cancelLoadData.current = null;
            }
        }

    }, []) // eslint-disable-line

    useEffect(() => {
        if (pageVisibility && forceReload) {
            loadData()
                .then(() => {
                    if (isMounted.current) {
                        setForceReload(false);
                    }
                })
        }
        isPageVisible.current = pageVisibility;
    }, [pageVisibility]) // eslint-disable-line

    const loadData = () => {
        return new Promise((resolve) => {
            if (!cancelLoadData.current) {
                api.Communications.statusCount({
                    scheduledSendDateTimeLocal: moment().local().format('YYYY-MM-DDTHH:mm:ss'),
                    failedDateUtc: moment().startOf('day').add(-7, 'days'),
                    deliveredDateUtc: moment().startOf('day').add(-7, 'days'),
                }, c => { cancelLoadData.current = c })
                    .then(({ data }) => {
                        if (isMounted.current && data) {
                            setScheduledCommunicationCount((data.scheduled ? data.scheduled : 0));
                            setFailedCommunicationCount((data.failed ? data.failed : 0));
                        }
                    })
                    .finally(() => {
                        isPageVisible.current = pageVisibility;
                        cancelLoadData.current = null;
                        resolve();
                    })
            }
            else {
                resolve();
            }
        });
    }

    const handleCommunicationLogClick = () => {
        if (scheduledCommunicationCount && scheduledCommunicationCount > 0) {
            navigate(rts.Communications.Scheduled);
        }
        else if (failedCommunicationCount && failedCommunicationCount > 0) {
            navigate(rts.Communications.Failed);
        }
        else {
            navigate(rts.Communications.Sent);
        }
    }

    return <>
        <div className={'d-flex align-items-center justify-content-center ml-2 pt-h'}>
            <button
                type='button'
                className='btn btn-icon header-icon'
                title='Communication log'
                onClick={handleCommunicationLogClick}
            >
                <i className='fal fa-paper-plane'></i>
                {
                    scheduledCommunicationCount && scheduledCommunicationCount > 0 ?
                        <>
                            <span className='badge badge-icon bg-success'>{scheduledCommunicationCount}</span>
                            {
                                failedCommunicationCount && failedCommunicationCount > 0 ?
                                    <span className='badge badge-solid-danger text-shadow-0 badge-dot float-right mr-1 mt-3'></span> : null
                            }
                        </> :
                        <>
                            {
                                failedCommunicationCount && failedCommunicationCount > 0 ?
                                    <span className='badge badge-icon bg-danger'>{failedCommunicationCount}</span> : null
                            }
                        </>
                }
            </button>
        </div>
    </>
}

export default NavbarCommunication;