import React, { useState, useEffect, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { GlobalHotKeys } from 'react-hotkeys';

import * as fn from '../../../utilities/_functions';

import './ConfirmModal.scss';

function ConfirmModal(props) {
    const isMounted = useRef(true);
    const readyTimer = useRef(null);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;
            if (readyTimer.current) { clearTimeout(readyTimer.current); readyTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const render = () => {
        if (props.show) {
            readyTimer.current = setTimeout(() => {
                setReady(true);
            }, 100)
        }

        return props.show ? <>
            <GlobalHotKeys
                keyMap={{
                    close: ['esc'],
                }}
                handlers={{
                    close: event => {
                        if (fn.isFunction(props.onCancel)) {
                            props.onCancel(event)
                        }
                    },
                }}
                allowChanges={true}
            />
            <div className={'bootbox modal confirm-modal modal-alert d-block' + (ready ? ' show' : '')} tabIndex='-1' role='dialog' aria-modal='true' style={{ zIndex: fn.getHighestZIndex() + 2 }}>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <FadeIn>
                            <div>
                                <div className='modal-header'>
                                    <h5 className='modal-title'>
                                        {
                                            props.icon ?
                                                props.icon :
                                                <i className='fal fa-exclamation-triangle text-warning mr-2'></i>
                                        }
                                        {
                                            props.message ?
                                                props.message :
                                                <>Do you wish to continue?</>
                                        }
                                    </h5>
                                </div>
                                <div className='modal-body'>
                                    <div className='bootbox-body'>
                                        <span className='bootbox-modal-icon-newline'>
                                            {
                                                props.description ?
                                                    props.description :
                                                    <span className={'description' + (props.descriptionClassName ? ` ${props.descriptionClassName}` : '')}><strong>Warning:</strong> This action cannot be undone!</span>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className='modal-footer'>
                                    {
                                        props.renderCustomControls && fn.isFunction(props.renderCustomControls) ? props.renderCustomControls() : null
                                    }
                                    {
                                        props.onOption1Click ?
                                            <button
                                                data-confirm-modal-option-1
                                                type='button'
                                                className={props.option1ClassName ? props.option1ClassName : 'btn btn-danger shadow-0 bootbox-accept'}
                                                disabled={props.option1Disabled}
                                                onClick={props.onOption1Click}
                                            >
                                                {props.option1Text ? props.option1Text : 'Yes'}
                                            </button> : null
                                    }
                                    {
                                        props.onOption2Click ?
                                            <button
                                                data-confirm-modal-option-2
                                                type='button'
                                                className={props.option2ClassName ? props.option2ClassName : 'btn btn-secondary shadow-0 bootbox-accept'}
                                                disabled={props.option2Disabled}
                                                onClick={props.onOption2Click}
                                            >
                                                {props.option2Text ? props.option2Text : 'Options'}
                                            </button> : null
                                    }
                                    {
                                        props.onOption3Click ?
                                            <button
                                                data-confirm-modal-option-3
                                                type='button'
                                                className={props.option3ClassName ? props.option3ClassName : 'btn btn-info shadow-0 bootbox-accept'}
                                                disabled={props.option3Disabled}
                                                onClick={props.onOption2Click}
                                            >
                                                {props.option3Text ? props.option3Text : 'Other'}
                                            </button> : null
                                    }
                                    <button
                                        data-confirm-modal-no
                                        type='button'
                                        className={props.noButtonClassName ? props.noButtonClassName : 'btn btn-default bootbox-cancel'}
                                        onClick={props.onCancel}
                                    >
                                        {props.cancelText ? props.cancelText : 'No'}
                                    </button>
                                </div>
                            </div>
                        </FadeIn>
                    </div>
                </div>
            </div>
            <div className={'modal-backdrop fade' + (ready ? ' show' : '')} style={{ zIndex: fn.getHighestZIndex() + 1 }}></div>
        </> : null
    }

    return <>{render()}</>
}

export default ConfirmModal;