import axios from 'axios';
import queryString from 'query-string';
import { appInsights } from './utilities/appInsights';
import * as rax from 'retry-axios';
import uuid from 'react-uuid';

import { AUTHORIZATION_KEY, AUTHORIZATION_TOKEN, ACCESS_TOKEN, CURRENT_USER } from './constants/storageKeys';

import * as fn from './utilities/_functions';

const apiInProgressRequests = [];  // Tracks what api calls are in progress
const apiDuplicateRequests = [];  // Tracks any duplicate calls

// This checks whether or not an outstanding api request with the same params and source that was made within the last 5 seconds.  
// If so, this is a likely caused by rerendering, so don't make the same call to the backend again.  Instead we track it, and once 
// the initial calls return, we also return the same response to the subsequent calls.
const trackInProgressRequest = (method, uri, optionObj, key, token, resolveCallback, rejectCallback) => {
    let id;
    let exist = false;
    const now = Date.now();
    const expiry = new Date(now + 5000).getTime(); // Only consider duplicate if there is the same call made within 5 seconds.
    const option = optionObj ? JSON.stringify(optionObj) : null;
    const stack = new Error().stack;
    const items = apiInProgressRequests.filter(a =>  // The duplicate match is pretty strict, it has the same HttpMethod, request uri, parameters, and call stack.
        a.method === method &&
        a.uri === uri &&
        a.option === option &&
        a.key === key &&
        a.token === token &&
        a.stack === stack
    );

    if (items != null && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
            if (now > items[i].expiry) {
                removeInProgressRequests(items[i].id, items[i].uri);
            }
            else {
                exist = true;
            }
        }
    }

    if (!exist) {
        id = uuid();
        apiInProgressRequests.push({ id, method, uri, option, key, token, stack, expiry });
    }
    else {
        apiDuplicateRequests.push({ method, uri, option, key, token, expiry, resolveCallback, rejectCallback });
    }

    return id;
}

// When a call returns from the backend, it checks to see if there are any duplicate calls in queue.  If so, return the same response to them.
// This function also clean up any expired duplicate as well.
const resolveDuplicateRequests = (response, method, uri, optionObj, key, token) => {
    const now = Date.now();
    const option = optionObj ? JSON.stringify(optionObj) : null;
    const items = apiDuplicateRequests.filter(a =>
        a.method === method &&
        a.uri === uri &&
        a.option === option &&
        a.key === key &&
        a.token === token
    );
    const expiredDuplicateItems = apiDuplicateRequests.filter(a => now > a.expiry);

    if (items != null && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
            if (fn.isFunction(items[i].resolveCallback)) {
                const responseClone = JSON.parse(JSON.stringify(response));
                items[i].resolveCallback(responseClone);
            }
        }

        for (let i = apiDuplicateRequests.length - 1; i >= 0; i--) {
            if (items.includes(apiDuplicateRequests[i])) {
                apiDuplicateRequests.splice(i, 1);
            }
        }
    }

    if (expiredDuplicateItems != null && expiredDuplicateItems.length > 0) {
        for (let ei = apiDuplicateRequests.length - 1; ei >= 0; ei--) {
            if (expiredDuplicateItems.includes(apiDuplicateRequests[ei])) {
                apiDuplicateRequests.splice(ei, 1);
            }
        }
    }
}

// When a call is rejected from the backend, it checks to see if there are any duplicate calls in queue.  If so, reject them as well.
// This function also clean up any expired duplicate as well.
const rejectDuplicateRequests = (error, method, uri, optionObj, key, token) => {
    const now = Date.now();
    const option = optionObj ? JSON.stringify(optionObj) : null;
    const items = apiDuplicateRequests.filter(a =>
        a.method === method &&
        a.uri === uri &&
        a.option === option &&
        a.key === key &&
        a.token === token
    );
    const expiredDuplicateItems = apiDuplicateRequests.filter(a => now > a.expiry);

    if (items != null && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
            if (fn.isFunction(items[i].rejectCallback)) {
                const errorClone = JSON.parse(JSON.stringify(error));
                items[i].rejectCallback(errorClone);
            }
        }

        for (let i = apiDuplicateRequests.length - 1; i >= 0; i--) {
            if (items.includes(apiDuplicateRequests[i])) {
                apiDuplicateRequests.splice(i, 1);
            }
        }
    }

    if (expiredDuplicateItems != null && expiredDuplicateItems.length > 0) {
        for (let ei = apiDuplicateRequests.length - 1; ei >= 0; ei--) {
            if (expiredDuplicateItems.includes(apiDuplicateRequests[ei])) {
                apiDuplicateRequests.splice(ei, 1);
            }
        }
    }
}

// This is basically a clean up function. It gets call after a request is returned from the backend.
const removeInProgressRequests = (id, uri) => {
    const now = Date.now();
    const index = apiInProgressRequests.findIndex(a => a.id === id && a.uri === uri);
    const expiredInProgressItems = apiInProgressRequests.filter(a => now > a.expiry);

    if (index > -1) {
        apiInProgressRequests.splice(index, 1);
    }

    if (expiredInProgressItems != null && expiredInProgressItems.length > 0) {
        for (let ei = apiInProgressRequests.length - 1; ei >= 0; ei--) {
            if (expiredInProgressItems.includes(apiInProgressRequests[ei])) {
                apiInProgressRequests.splice(ei, 1);
            }
        }
    }
}

const trackError = (client, uri, method, error, option, callback, key, token) => {
    const user = JSON.parse(window.localStorage.getItem(CURRENT_USER));

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Response data:', error.response.data);
        console.log('Response status:', error.response.status);
        console.log('Response headers:', error.response.headers);

        appInsights.trackEvent({
            name: 'ApiErrorResponse',
            properties: {
                reference: client && client.default ? client.default : '',
                user: user ? { emailAddress: user.emailAddress, fullName: user.fullName } : '',
                page: window.location.href,
                uri: uri,
                method: method,
                option: option,
                callback: callback,
                key: key,
                token: token,
                error: error && error.toString(),
                errorRequest: error && error.request ? error.request : '',
                errorResponse: error && error.response ? error.response : '',
                errorMessage: error && error.message ? error.message : '',
                errorConfig: error && error.config ? error.config : '',
            }
        });
    } else if (error.request) {
        // The request was made but no response was received
        // This happens for CORS errors among other things
        console.log('Request error:', error.request);
        if (error.message.includes('Network Error')) {

            console.log('CORS error: Likely a CORS policy issue or network error');

            appInsights.trackEvent({
                name: 'ApiError',
                properties: {
                    reference: client && client.default ? client.default : '',
                    user: user ? { emailAddress: user.emailAddress, fullName: user.fullName } : '',
                    page: window.location.href,
                    uri: uri,
                    method: method,
                    option: option,
                    callback: callback,
                    key: key,
                    token: token,
                    error: error && error.toString(),
                    errorRequest: error && error.request ? error.request : '',
                    errorMessage: error && error.message ? error.message : '',
                    errorConfig: error && error.config ? error.config : '',
                }
            });
        }
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error message:', error.message);

        appInsights.trackEvent({
            name: 'ApiError',
            properties: {
                reference: client && client.default ? client.default : '',
                user: user ? { emailAddress: user.emailAddress, fullName: user.fullName } : '',
                page: window.location.href,
                uri: uri,
                method: method,
                option: option,
                callback: callback,
                key: key,
                token: token,
                error: error && error.toString(),
                errorMessage: error && error.message ? error.message : '',
            }
        });
    }
}

const cleanObj = (obj) => {
    if (obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }
    return obj;
}

const getConfig = (key, token) => {
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
    const authorizationKey = key ? key : window.localStorage.getItem(AUTHORIZATION_KEY);
    const authorizationToken = token ? token : window.localStorage.getItem(AUTHORIZATION_TOKEN);
    let config = {
        baseURL: `${process.env.REACT_APP_APP_API_PROTOCOL}://${process.env.REACT_APP_APP_API_DOMAIN}`,
        timeout: process.env.REACT_APP_APP_API_TIMEOUT,
        headers: {},
        referrerPolicy: 'origin-when-cross-origin',
    };

    if (accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`;
    }

    if (authorizationKey && authorizationToken) {
        config.headers.__GKIKY__ = authorizationKey;
        config.headers.__GKITN__ = authorizationToken;
    }

    config.headers.__GKIURL__ = window.location.href;

    return config;
};

const api = {
    get: (uri, cancelCallback) => {
        const client = axios.create(getConfig());
        const CancelToken = axios.CancelToken;

        client.defaults.raxConfig = { retry: 1, retryDelay: 1000, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        const request = new Promise((resolve, reject) => {
            const id = trackInProgressRequest('GET', uri, null, null, null, (r) => { resolve(r); }, (err) => { reject(err); });

            if (id) {
                client.get(uri, {
                    cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                        function executor(c) {
                            cancelCallback(c);
                        }) : null
                })
                    .then(response => {
                        resolve(response);
                        resolveDuplicateRequests(response, 'GET', uri, null, null, null);
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            trackError(client, uri, 'GET', error, null, cancelCallback);
                            reject(error);
                            rejectDuplicateRequests(error, 'GET', uri, null, null, null);
                        }
                    })
                    .finally(() => {
                        removeInProgressRequests(id, uri);
                    })
            }
        });

        return request;
    },
    post: (uri, option, cancelCallback, key, token) => {
        const client = axios.create(getConfig(key, token));
        const CancelToken = axios.CancelToken;
        const cleanedOption = cleanObj(option);

        client.defaults.raxConfig = { retry: 1, retryDelay: 1000, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        const request = new Promise((resolve, reject) => {
            const id = trackInProgressRequest('POST', uri, cleanedOption, key, token, (r) => { resolve(r); }, (err) => { reject(err); });

            if (id) {
                client.post(uri, cleanedOption, {
                    cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                        function executor(c) {
                            cancelCallback(c);
                        }) : null
                })
                    .then(response => {
                        resolve(response);
                        resolveDuplicateRequests(response, 'POST', uri, cleanedOption, key, token);
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            trackError(client, uri, 'POST', error, cleanedOption, cancelCallback, key, token);
                            reject(error);
                            rejectDuplicateRequests(error, 'POST', uri, cleanedOption, key, token);
                        }
                    })
                    .finally(() => {
                        removeInProgressRequests(id, uri);
                    })
            }
        });

        return request;
    },
    put: (uri, option, cancelCallback, key, token) => {
        const client = axios.create(getConfig(key, token));
        const CancelToken = axios.CancelToken;
        const cleanedOption = cleanObj(option);

        client.defaults.raxConfig = { retry: 1, retryDelay: 1000, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        const request = new Promise((resolve, reject) => {
            const id = trackInProgressRequest('PUT', uri, cleanedOption, key, token, (r) => { resolve(r); }, (err) => { reject(err); });

            if (id) {
                client.put(uri, cleanedOption, {
                    cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                        function executor(c) {
                            cancelCallback(c);
                        }) : null
                })
                    .then(response => {
                        resolve(response);
                        resolveDuplicateRequests(response, 'PUT', uri, cleanedOption, key, token);
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            trackError(client, uri, 'PUT', error, cleanedOption, cancelCallback, key, token);
                            reject(error);
                            rejectDuplicateRequests(error, 'PUT', uri, cleanedOption, key, token);
                        }
                    })
                    .finally(() => {
                        removeInProgressRequests(id, uri);
                    })
            }
            else {
                reject('Request in progress.');
            }
        });

        return request;
    },
    delete: (uri, option, cancelCallback) => {
        const client = axios.create(getConfig());
        const CancelToken = axios.CancelToken;
        const cleanedOption = cleanObj(option);

        client.defaults.raxConfig = { retry: 1, retryDelay: 1000, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        if (cleanedOption) {
            return new Promise((resolve, reject) => {
                const id1 = trackInProgressRequest('DELETE', uri, cleanedOption, null, null, (r) => { resolve(r); }, (err) => { reject(err); });
                if (id1) {
                    client.delete(uri, {
                        data: cleanedOption,
                        cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                            function executor(c) {
                                cancelCallback(c);
                            }) : null
                    })
                        .then(response => {
                            resolve(response);
                            resolveDuplicateRequests(response, 'DELETE', uri, cleanedOption, null, null);
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                trackError(client, uri, 'DELETE', error, cleanedOption, cancelCallback);
                                reject(error);
                                rejectDuplicateRequests(error, 'DELETE', uri, cleanedOption, null, null);
                            }
                        })
                        .finally(() => {
                            removeInProgressRequests(id1, uri);
                        })
                }
                else {
                    reject('Request in progress.');
                }
            });
        } else {
            return new Promise((resolve, reject) => {
                const id2 = trackInProgressRequest('DELETE', uri, cleanedOption, null, null, (r) => { resolve(r); }, (err) => { reject(err); });
                if (id2) {
                    client.delete(uri, {
                        cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                            function executor(c) {
                                cancelCallback(c);
                            }) : null
                    })
                        .then(response => {
                            resolve(response);
                            resolveDuplicateRequests(response, 'DELETE', uri, cleanedOption, null, null);
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                trackError(client, uri, 'DELETE', error, cleanedOption, cancelCallback);
                                reject(error);
                                rejectDuplicateRequests(error, 'DELETE', uri, cleanedOption, null, null);
                            }
                        })
                        .finally(() => {
                            removeInProgressRequests(id2, uri);
                        })
                }
                else {
                    reject('Request in progress.');
                }
            });
        }
    },
    file: (uri, cancelCallback) => {
        let config = getConfig();

        config.responseType = 'blob';
        config.timeout = 0;

        const client = axios.create(config);
        const CancelToken = axios.CancelToken;

        client.defaults.raxConfig = { retry: 1, retryDelay: 1000, instance: client, backoffType: 'exponential' };
        rax.attach(client);

        const request = new Promise((resolve, reject) => {
            client.get(uri, {
                cancelToken: fn.isFunction(cancelCallback) ? new CancelToken(
                    function executor(c) {
                        cancelCallback(c);
                    }) : null
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        trackError(client, uri, 'GET', error, null, cancelCallback);
                        reject(error);
                    }
                })
        });

        return request;
    }
}

const Authorize = {
    start: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_AUTHORIZE_API_URI}`, option, cancelCallback);
    },
    switch: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_AUTHORIZE_API_URI}/switch`, option, cancelCallback);
    },
    verify: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_AUTHORIZE_API_URI}/verify`, option, cancelCallback);
    },
    updatePassword: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_AUTHORIZE_API_URI}/update-password`, option, cancelCallback);
    },
    updatePin: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_AUTHORIZE_API_URI}/update-pin`, option, cancelCallback);
    },
    failover: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_AUTHORIZE_API_URI}/failover`, option, cancelCallback);
    },
}

const TenantUsers = {
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TENANT_USER_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const OrganizationUsers = {
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TENANT_USER_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Users = {
    get: (id, full, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_USERS_API_URI}/${id}${!!full ? '?full=true' : ''}`, cancelCallback);
    },
    getForAdmin: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_USERS_API_URI}/${id || '_'}/for-admin`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_USERS_API_URI}`, option, cancelCallback);
    },
    updateProfile: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_USERS_API_URI}/${id}`, option, cancelCallback);
    },
    updateRoles: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_USERS_API_URI}/${id}/roles`, option, cancelCallback);
    },
    updateTenants: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_USERS_API_URI}/${id}/tenants`, option, cancelCallback);
    },
    updatePassword: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_USERS_API_URI}/${id}/update-password`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_USERS_API_URI}/${id}`, null, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_USERS_API_URI}`, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_USER_SEARCH_API_URI}`, option, cancelCallback);
    },
    tenants: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_USERS_API_URI}/${id}/tenants`, cancelCallback);
    },
}

const Groups = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_GROUPS_API_URI}`, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_GROUPS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_GROUPS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_GROUPS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_GROUPS_API_URI}/${id}`, null, cancelCallback);
    }
}
const Settings = {
    all: (userId, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SETTINGS_API_URI}/${userId}`, cancelCallback);
    },
    get: (userId, key, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SETTINGS_API_URI}/${userId}?key=${key}`, cancelCallback);
    },
    save: (userId, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SETTINGS_API_URI}/${userId}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SETTING_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Appointments = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_APPOINTMENTS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_APPOINTMENTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_APPOINTMENTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_APPOINTMENTS_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_APPOINTMENT_SEARCH_API_URI}`, option, cancelCallback);
    },
    status: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_APPOINTMENTS_API_URI}/${id}/status`, option, cancelCallback);
    },
    confirm: (key, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_APPOINTMENT_CONFIRM_API_URI}/?key=${encodeURIComponent(key)}`, cancelCallback);
    },
    conflict: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_APPOINTMENT_VALIDATION_API_URI}/conflict`, option, cancelCallback);
    },
}

const GroupAppointments = {
    get: (groupId, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_GROUP_APPOINTMENTS_API_URI}/${groupId}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_GROUP_APPOINTMENTS_API_URI}`, option, cancelCallback);
    },
    update: (groupId, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_GROUP_APPOINTMENTS_API_URI}/${groupId}`, option, cancelCallback);
    },
    delete: (groupId, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_GROUP_APPOINTMENTS_API_URI}/${groupId}`, option, cancelCallback);
    },
    status: (groupId, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_GROUP_APPOINTMENTS_API_URI}/${groupId}/status`, option, cancelCallback);
    },
}

const Customers = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_CUSTOMERS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMERS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_CUSTOMERS_API_URI}/${id}`, option, cancelCallback);
    },
    deactivate: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_CUSTOMERS_API_URI}/${id}`, option, cancelCallback);
    },
    reactivate: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMERS_API_URI}/${id}/reactivate`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMER_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, includeDeactivated = false, includeSystemCustomer = false, limit = -1, offset = -1, showFullDetail = false, showAll = false, showResult = true, showCount = false, exclude = null, cancelCallback = null) => {
        const qs = queryString.stringify({
            term: term,
            includeDeactivated: includeDeactivated,
            includeSystemCustomer: includeSystemCustomer,
            limit: limit,
            offset: offset,
            showFullDetail: showFullDetail,
            showAll: showAll,
            showResult: showResult,
            showCount: showCount,
            exclude: (exclude && Array.isArray(exclude) && exclude.length > 0 ? exclude.join(',') : null),
        });
        return api.get(`${process.env.REACT_APP_CUSTOMER_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMER_LIST_API_URI}`, option, cancelCallback);
    },
    healthCard: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_HEALTH_CARD_API_URI}/`, option, cancelCallback);
    },
    storeCreditTransfer: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMERS_API_URI}/${id}/store-credit-transfer`, option, cancelCallback);
    },
    exportList: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMER_LIST_API_URI}/export`, option, cancelCallback);
    }
}

const Resources = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_RESOURCES_API_URI}`, cancelCallback);
    }
}

const Services = {
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SERVICES_API_URI}`, option, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SERVICES_API_URI}/${id}`, cancelCallback);
    },
    all: (includeDeactivated, cancelCallback) => {
        const qs = queryString.stringify({
            includeDeactivated: includeDeactivated,
        });
        return api.get(`${process.env.REACT_APP_SERVICES_API_URI}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SERVICE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SERVICE_SEARCH_API_URI}/${encodeURIComponent(term)}`, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_SERVICES_API_URI}/${id}`, null, cancelCallback);
    },
    updateTimelineItems: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_SERVICES_API_URI}/${id}/update-timeline-items`, option, cancelCallback);
    },
}

const TimelineItems = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TIMELINE_ITEM_API_URI}`, cancelCallback);
    },
}

const ServiceAttributes = {
    fullSearch: (term, limit, offset, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset
        });
        return api.get(`${process.env.REACT_APP_SERVICE_ATTRIBUTES_SEARCH_API_URI}/${encodeURI(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
    // id here Service."Id", not ServiceAttributes."Id". If ServiceAttributes does not exist for this service id, return the Service entity instead
    getForUpdate: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SERVICE_ATTRIBUTES_API_URI}/${id}/for-update`, cancelCallback);
    },
    getForReload: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SERVICE_ATTRIBUTES_API_URI}/${id}/for-reload`, cancelCallback);
    },
    update: (id, data, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_SERVICE_ATTRIBUTES_API_URI}/${id}`, data, cancelCallback);
    },
    deletePending: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_SERVICE_ATTRIBUTES_API_URI}/${id}/pending`, null, cancelCallback);
    },
}

const ServiceUserOverrides = {
    // id here Service."Id", not ServiceOverrides."Id"
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SERVICE_USER_OVERRIDES_API_URI}/${id}`, cancelCallback);
    },
    update: (id, data, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SERVICE_USER_OVERRIDES_API_URI}/${id}`, data, cancelCallback);
    },
}

const ServiceEligibility = {
    check: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SERVICE_ELIGIBILITY_API_URI}`, option, cancelCallback);
    },
}

const ReferenceData = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERENCE_DATA_API_URI}`, cancelCallback);
    }
}

const EntityAlerts = {
    all: (entityType, entityId, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_ENTITY_ALERT_API_URI}/${entityType}/${entityId}`, cancelCallback);
    },
    hide: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_ENTITY_ALERT_API_URI}/${id}/hide`, null, cancelCallback);
    },
}

const ExamDatasetGroups = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_DATASET_GROUPS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_DATASET_GROUPS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_EXAM_DATASET_GROUPS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_EXAM_DATASET_GROUPS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_DATASET_GROUP_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const ExamDatasets = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_DATASETS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_DATASETS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_DATASETS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_EXAM_DATASETS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        if (id) {
            return api.delete(`${process.env.REACT_APP_EXAM_DATASETS_API_URI}`, { ids: [id] }, cancelCallback);
        }
        else if (option) {
            return api.delete(`${process.env.REACT_APP_EXAM_DATASETS_API_URI}`, option, cancelCallback);
        }
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_DATASET_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_EXAM_DATASET_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const ExamDatasetCategories = {
    all: (examDatasetGroupId, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_DATASET_CATEGORIES_API_URI}/${examDatasetGroupId}`, cancelCallback);
    }
}

const ReferralTenantExamDatasetGroups = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERRAL_TENANT_EXAM_DATASET_GROUPS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERRAL_TENANT_EXAM_DATASET_GROUPS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_TENANT_EXAM_DATASET_GROUPS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_REFERRAL_TENANT_EXAM_DATASET_GROUPS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_REFERRAL_TENANT_EXAM_DATASET_GROUPS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_TENANT_EXAM_DATASET_GROUP_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const ReferralUserExamDatasetGroups = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERRAL_USER_EXAM_DATASET_GROUPS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERRAL_USER_EXAM_DATASET_GROUPS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_USER_EXAM_DATASET_GROUPS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_REFERRAL_USER_EXAM_DATASET_GROUPS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_REFERRAL_USER_EXAM_DATASET_GROUPS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_USER_EXAM_DATASET_GROUP_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const ReferralTemplates = {
    get: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERRAL_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_REFERRAL_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const ReferralPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERRAL_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const Referrals = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERRALS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRALS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_REFERRALS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_REFERRALS_API_URI}/${id}`, null, cancelCallback);
    },
    complete: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRALS_API_URI}/${id}/complete`, option, cancelCallback);
    },
    sign: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRALS_API_URI}/${id}/sign`, null, cancelCallback);
    },
    unSign: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRALS_API_URI}/${id}/un-sign`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const ReferralAppointments = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REFERRAL_APPOINTMENTS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_APPOINTMENTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_REFERRAL_APPOINTMENTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_REFERRAL_APPOINTMENTS_API_URI}/${id}`, null, cancelCallback);
    },
    manual: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_APPOINTMENTS_API_URI}/${id}/manual`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_APPOINTMENT_SEARCH_API_URI}`, option, cancelCallback);
    },
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REFERRAL_APPOINTMENT_LIST_API_URI}`, option, cancelCallback);
    },
}

const MedicalReportTenantExamDatasetGroups = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_MEDICAL_REPORT_TENANT_EXAM_DATASET_GROUPS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_MEDICAL_REPORT_TENANT_EXAM_DATASET_GROUPS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_TENANT_EXAM_DATASET_GROUPS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_MEDICAL_REPORT_TENANT_EXAM_DATASET_GROUPS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_MEDICAL_REPORT_TENANT_EXAM_DATASET_GROUPS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_TENANT_EXAM_DATASET_GROUP_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const MedicalReportUserExamDatasetGroups = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_MEDICAL_REPORT_USER_EXAM_DATASET_GROUPS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_MEDICAL_REPORT_USER_EXAM_DATASET_GROUPS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_USER_EXAM_DATASET_GROUPS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_MEDICAL_REPORT_USER_EXAM_DATASET_GROUPS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_MEDICAL_REPORT_USER_EXAM_DATASET_GROUPS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_USER_EXAM_DATASET_GROUP_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const MedicalReportTemplates = {
    get: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_MEDICAL_REPORT_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_MEDICAL_REPORT_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const MedicalReportPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_MEDICAL_REPORT_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const MedicalReports = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_MEDICAL_REPORTS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_MEDICAL_REPORTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_MEDICAL_REPORTS_API_URI}/${id}`, null, cancelCallback);
    },
    complete: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORTS_API_URI}/${id}/complete`, option, cancelCallback);
    },
    sign: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORTS_API_URI}/${id}/sign`, null, cancelCallback);
    },
    unSign: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORTS_API_URI}/${id}/un-sign`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const MedicalReportResponses = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_MEDICAL_REPORT_RESPONSES_API_URI}/${id}`, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_MEDICAL_REPORT_RESPONSES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_MEDICAL_REPORT_RESPONSES_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MEDICAL_REPORT_RESPONSE_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Notes = {
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_NOTES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_NOTES_API_URI}/${id}`, null, cancelCallback);
    },
    pin: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTES_API_URI}/${id}/pin`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTE_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Schedules = {
    get: (start, end, userId, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SCHEDULES_API_URI}/${start}/${end}${(userId ? `/${userId}` : '')}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SCHEDULES_API_URI}`, cancelCallback);
    },
    conflict: (option, cancelCallback) => {
        option.detectConflict = true;
        return api.post(`${process.env.REACT_APP_SCHEDULES_API_URI}`, option, cancelCallback)
    },
    save: (option, cancelCallback) => {
        option.detectConflict = false;
        return api.post(`${process.env.REACT_APP_SCHEDULES_API_URI}`, option, cancelCallback)
    },
    delete: (option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_SCHEDULES_API_URI}`, option, cancelCallback);
    }
}

const TimeslotSchedules = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TIMESLOT_SCHEDULES_API_URI}`, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TIMESLOT_SCHEDULES_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TIMESLOT_SCHEDULES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_TIMESLOT_SCHEDULES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_TIMESLOT_SCHEDULES_API_URI}/${id}`, null, cancelCallback);
    },
    clone: (dayOfWeek, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TIMESLOT_SCHEDULES_API_URI}/${dayOfWeek}/clone`, option, cancelCallback)
    }
}

const BusinessHours = {
    get: (start, end, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_BUSINESS_HOURS_API_URI}/${start}/${end}`, cancelCallback);
    },
    save: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_BUSINESS_HOURS_API_URI}`, option, cancelCallback);
    },
}

const BusinessSchedules = {
    get: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_BUSINESS_SCHEDULES_API_URI}`, cancelCallback);
    },
    save: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_BUSINESS_SCHEDULES_API_URI}`, option, cancelCallback);
    },
}

const BusinessDays = {
    get: (date, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_BUSINESS_DAYS_API_URI}/${date}`, cancelCallback);
    },
    save: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_BUSINESS_DAYS_API_URI}`, option, cancelCallback);
    },
    delete: (date, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_BUSINESS_DAYS_API_URI}/${date}`, null, cancelCallback);
    },
}

const CustomerRelationships = {
    get: (customerId, loadProperties, includeDeceased, cancelCallback) => {
        const qs = queryString.stringify({
            loadProperties: loadProperties,
            includeDeceased: includeDeceased,
        });
        return api.get(`${process.env.REACT_APP_CUSTOMER_RELATIONSHIP_API_URI}/${customerId}${qs && qs.toString() ? `?${qs.toString()}` : ''}`, cancelCallback);
    },
    suggestions: (customerId, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_CUSTOMER_RELATIONSHIP_API_URI}/${customerId}/suggestions`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMER_RELATIONSHIP_API_URI}`, option, cancelCallback);
    },
    delete: (option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_CUSTOMER_RELATIONSHIP_API_URI}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMER_RELATIONSHIP_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Exams = {
    get: (id, loadAttributes, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAMS_API_URI}/${id}${loadAttributes ? `?loadAttributes=${loadAttributes === true ? 'true' : 'false'}` : ''}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAMS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_EXAMS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_EXAMS_API_URI}/${id}`, option, cancelCallback);
    },
    upload: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAMS_API_URI}/${id}/upload`, option, cancelCallback);
    },
    download: (id, option, cancelCallback) => {
        const qs = queryString.stringify({
            fileName: option.fileName,
            guid: option.id,
        });
        return api.file(`${process.env.REACT_APP_EXAMS_API_URI}/${id}/download${qs ? `?${qs}` : ''}`, option, cancelCallback);
    },
    complete: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAMS_API_URI}/${id}/complete`, option, cancelCallback);
    },
    reopen: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAMS_API_URI}/${id}/reopen`, option, cancelCallback);
    },
    prefill: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAMS_API_URI}/${id}/prefill`, option, cancelCallback);
    },
    submitPublicInsurance: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAMS_API_URI}/${id}/submit-public-insurance`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_SEARCH_API_URI}`, option, cancelCallback);
    },
    previous: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_SEARCH_API_URI}/previous`, option, cancelCallback);
    },
    templates: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_SEARCH_API_URI}/templates`, option, cancelCallback);
    },
}

const PredefinedData = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_PREDEFINED_DATA_API_URI}/${id}`, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_PREDEFINED_DATA_SEARCH_API_URI}`, option, cancelCallback);
    }
}

const Products = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRODUCTS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRODUCTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRODUCTS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCT_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, showAll, showResult, showCount, typeId, display, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showCount: showCount,
            showResult: showResult,
            typeId: typeId,
            display: display,
        });
        return api.get(`${process.env.REACT_APP_PRODUCT_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
}

const ProductOptions = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRODUCT_OPTIONS_API_URI}`, cancelCallback);
    }
}

const ProductTypes = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRODUCT_TYPES_API_URI}`, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRODUCT_TYPES_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCT_TYPES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRODUCT_TYPES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRODUCT_TYPES_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCT_TYPE_SEARCH_API_URI}`, option, cancelCallback);
    },
    brandCounts: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCT_TYPE_SEARCH_API_URI}/brand-counts`, option, cancelCallback);
    },
}

const ProductBrands = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRODUCT_BRANDS_API_URI}`, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRODUCT_BRANDS_API_URI}${id ? `/${id}` : ''}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCT_BRANDS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRODUCT_BRANDS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRODUCT_BRANDS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCT_BRAND_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const ProductSuppliers = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRODUCT_SUPPLIERS_API_URI}`, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRODUCT_SUPPLIERS_API_URI}${id ? `/${id}` : ''}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCT_SUPPLIERS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRODUCT_SUPPLIERS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRODUCT_SUPPLIERS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRODUCT_SUPPLIER_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Inventory = {
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INVENTORY_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, showAll, showResult, showCount, typeId, inventory, isInStockOnly, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showCount: showCount,
            showResult: showResult,
            typeId: typeId,
            inventory: inventory,
            isInStockOnly: isInStockOnly,
        });
        return api.get(`${process.env.REACT_APP_INVENTORY_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INVENTORY_LIST_API_URI}`, option, cancelCallback);
    },
    exportList: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INVENTORY_LIST_API_URI}/export`, option, cancelCallback);
    },
}

const Purchases = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}`, cancelCallback);
    },
    start: (customerId, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASES_API_URI}`, { customerId }, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}`, null, cancelCallback);
    },
    complete: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}/complete`, null, cancelCallback);
    },
    refund: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}/refund`, option, cancelCallback);
    },
    void: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}/void`, option, cancelCallback);
    },
    noCharge: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}/no-charge`, option, cancelCallback);
    },
    uncollectible: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}/uncollectible`, option, cancelCallback);
    },
    updateCost: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASES_API_URI}/${id}/update-cost`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASE_SEARCH_API_URI}`, option, cancelCallback);
    },
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASE_LIST_API_URI}`, option, cancelCallback);
    },
    exportList: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PURCHASE_LIST_API_URI}/export`, option, cancelCallback);
    },
}

const TaxRates = {
    get: (date, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TAX_RATES_API_URI}/${date}`, cancelCallback);
    },
}

const WorkOrders = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDERS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDER_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, showAll, showResult, showCount, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showCount: showCount,
            showResult: showResult,
        });
        return api.get(`${process.env.REACT_APP_WORK_ORDER_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
    link: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}/link`, option, cancelCallback);
    },
    unlink: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}/unlink`, option, cancelCallback);
    },
    replace: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}/replace`, option, cancelCallback);
    },
    copy: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}/copy`, option, cancelCallback);
    },
    redo: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}/redo`, null, cancelCallback);
    },
    status: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDERS_API_URI}/${id}/status`, option, cancelCallback);
    },
}

const PatientProfiles = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PATIENT_PROFILES_API_URI}/${id}`, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PATIENT_PROFILE_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const PatientRecalls = {
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PATIENT_RECALLS_API_URI}`, option, cancelCallback);
    },
}

const ReviewRequests = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REVIEW_REQUESTS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REVIEW_REQUESTS_API_URI}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REVIEW_REQUEST_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const ReviewFeedbacks = {
    get: (key, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_REVIEW_FEEDBACKS_API_URI}/${key}`, cancelCallback);
    },
    create: (key, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_REVIEW_FEEDBACKS_API_URI}/${key}`, option, cancelCallback);
    },
}

const ExamInputs = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_INPUTS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_INPUTS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_INPUTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_EXAM_INPUTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_EXAM_INPUTS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_INPUT_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_EXAM_INPUT_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const ExamTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_EXAM_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_EXAM_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    clone: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_TEMPLATES_API_URI}/${id}/clone`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_EXAM_TEMPLATE_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const ExamPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXAM_PUBLISHED_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXAM_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const WorkOrderInputs = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_WORK_ORDER_INPUTS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_WORK_ORDER_INPUTS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDER_INPUTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_WORK_ORDER_INPUTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_WORK_ORDER_INPUTS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDER_INPUT_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_WORK_ORDER_INPUT_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const WorkOrderTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_WORK_ORDER_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_WORK_ORDER_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDER_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_WORK_ORDER_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_WORK_ORDER_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDER_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_WORK_ORDER_TEMPLATE_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const Roles = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_ROLES_API_URI}`, cancelCallback);
    },
    getForAdmin: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_ROLES_API_URI}/for-admin`, cancelCallback);
    }
}

const Accesses = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_ACCESSES_API_URI}`, cancelCallback);
    }
}
const Attachments = {
    download: (id, cancelCallback) => {
        return api.file(`${process.env.REACT_APP_ATTACHMENTS_API_URI}/${id}/download`, cancelCallback);
    }
}

const CustomerPrivateInsurances = {
    get: (customerId, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_CUSTOMER_PRIVATE_INSURANCE_POLICIES_API_URI}/${customerId}`, cancelCallback);
    },
    create: (customerId, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMER_PRIVATE_INSURANCE_POLICIES_API_URI}/${customerId}`, option, cancelCallback);
    },
    update: (customerId, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_CUSTOMER_PRIVATE_INSURANCE_POLICIES_API_URI}/${customerId}`, option, cancelCallback);
    },
    delete: (customerId, option, cancelCallback) => {
        switch (option.action.toLowerCase()) {
            case 'unlink':
                return api.delete(`${process.env.REACT_APP_CUSTOMER_PRIVATE_INSURANCE_POLICIES_API_URI}/${customerId}`, option, cancelCallback);

            case 'delete':
                return api.delete(`${process.env.REACT_APP_PRIVATE_INSURANCE_POLICIES_API_URI}/${option.privateInsurancePolicyId}`, cancelCallback);

            default:
                return Promise.reject();
        }
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMER_PRIVATE_INSURANCE_POLICY_SEARCH_API_URI}`, option, cancelCallback);
    },
    reorder: (customerId, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CUSTOMER_PRIVATE_INSURANCE_POLICIES_API_URI}/${customerId}/reorder`, option, cancelCallback);
    },
}

const PrivateInsuranceProviders = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRIVATE_INSURANCE_PROVIDERS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRIVATE_INSURANCE_PROVIDERS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRIVATE_INSURANCE_PROVIDERS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRIVATE_INSURANCE_PROVIDERS_API_URI}/${id}`, option, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRIVATE_INSURANCE_PROVIDERS_API_URI}`, cancelCallback);
    },
}

const Payments = {
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PAYMENTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PAYMENTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PAYMENTS_API_URI}/${id}`, option, cancelCallback);
    },
}

const Todos = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TODOS_API_URI}/${id}`, cancelCallback);
    },
    // Get is retrieving the todos created by this user
    getByCreator: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TODOS_API_URI}/creator`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TODOS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_TODOS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_TODOS_API_URI}/${id}`, option, cancelCallback);
    },
    addUsers: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TODOS_API_URI}/${id}/add-users`, option, cancelCallback);
    },
    getCompletionStatus: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TODOS_API_URI}/${id}/completion-status`, cancelCallback);
    },
    resetCompletionStatus: (id, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_TODOS_API_URI}/${id}/reset-status`, null, cancelCallback);
    }
}

const TodoNotes = {
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TODO_NOTES_API_URI}`, option, cancelCallback);
    },
    get: (instanceId, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TODO_NOTES_API_URI}/instance/${instanceId}`, cancelCallback);
    },
    fullSearch: (term, limit, offset, cancelCallback) => {
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_TODO_NOTES_API_URI}/${id}`, null, cancelCallback);
    },
}

const TodoInstances = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TODOINSTANCES_API_URI}/${id}`, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_TODOINSTANCES_API_URI}/${id}`, option, cancelCallback);
    },
    status: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TODOINSTANCES_API_URI}/${id}/status`, option, cancelCallback);
    },
    note: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TODOINSTANCES_API_URI}/${id}/note`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TODOINSTANCE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, userId, status, limit, offset, cancelCallback) => {
        const qs = queryString.stringify({
            userId, status, limit, offset
        });
        return api.get(`${process.env.REACT_APP_TODOINSTANCE_SEARCH_API_URI}/${encodeURI(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TODOINSTANCE_LIST_API_URI}`, option, cancelCallback);
    },
    batchComplete: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TODOINSTANCES_API_URI}/_/batch-complete`, option, cancelCallback);
    }
}

const Notifications = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_NOTIFICATIONS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTIFICATIONS_API_URI}`, option, cancelCallback);
    },
    read: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTIFICATIONS_API_URI}/${id}/read`, null, cancelCallback);
    },
    expire: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTIFICATIONS_API_URI}/${id}/expire`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTIFICATION_SEARCH_API_URI}`, option, cancelCallback);
    },
    summary: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTIFICATION_SEARCH_API_URI}/summary`, option, cancelCallback);
    },
    batchExpire: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_NOTIFICATIONS_API_URI}/_/batch-expire`, option, cancelCallback);
    }
}

const Transactions = {
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TRANSACTION_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const TransactionItems = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TRANSACTION_ITEMS_API_URI}/${id}`, cancelCallback);
    },
}

const TransactionPayments = {
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TRANSACTION_PAYMENT_SEARCH_API_URI}`, option, cancelCallback);
    },
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TRANSACTION_PAYMENT_LIST_API_URI}`, option, cancelCallback);
    },
    exportList: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TRANSACTION_PAYMENT_LIST_API_URI}/export`, option, cancelCallback);
    },
}

const TransactionSales = {
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TRANSACTION_SALES_LIST_API_URI}`, option, cancelCallback);
    },
    exportList: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TRANSACTION_SALES_LIST_API_URI}/export`, option, cancelCallback);
    },
}

const WorkOrderPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_WORK_ORDER_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_WORK_ORDER_PUBLISHED_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDER_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const WorkOrderItems = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_WORK_ORDER_ITEMS_API_URI}/${id}`, cancelCallback);
    },
    override: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_WORK_ORDER_ITEMS_API_URI}/${id}/override`, option, cancelCallback);
    },
}

const Prescriptions = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTIONS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTIONS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRESCRIPTIONS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRESCRIPTIONS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, showAll, showResult, showCount, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showCount: showCount,
            showResult: showResult,
        });
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
    sign: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTIONS_API_URI}/${id}/sign`, null, cancelCallback);
    },
    unSign: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTIONS_API_URI}/${id}/un-sign`, null, cancelCallback);
    },
    requestFinalize: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTIONS_API_URI}/${id}/request-finalize`, option, cancelCallback);
    }
}

const PrescriptionTypes = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_TYPES_API_URI}`, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_TYPES_API_URI}/${id}`, cancelCallback);
    },
}

const PrescriptionInputs = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_INPUTS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_INPUTS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_INPUTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRESCRIPTION_INPUTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRESCRIPTION_INPUTS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_INPUT_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_INPUT_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const PrescriptionTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRESCRIPTION_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRESCRIPTION_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_TEMPLATE_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const PrescriptionPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_PUBLISHED_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const PrescriptionPrintTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_PRINT_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_PRINT_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_PRINT_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRESCRIPTION_PRINT_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRESCRIPTION_PRINT_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_PRINT_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_PRINT_TEMPLATE_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const PrescriptionPrintPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_PRINT_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRESCRIPTION_PRINT_PUBLISHED_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRESCRIPTION_PRINT_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const Pretests = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETESTS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRETESTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRETESTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRETESTS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRETEST_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, showAll, showResult, showCount, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showCount: showCount,
            showResult: showResult,
        });
        return api.get(`${process.env.REACT_APP_PRETEST_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    },
}

const PretestTypes = {
    all: (full, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETEST_TYPES_API_URI}${full ? '?full=true' : ''}`, cancelCallback);
    },
    get: (id, full, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETEST_TYPES_API_URI}/${id}${full ? '?full=true' : ''}`, cancelCallback);
    },
}

const PretestInputs = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETEST_INPUTS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETEST_INPUTS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRETEST_INPUTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRETEST_INPUTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRETEST_INPUTS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRETEST_INPUT_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_PRETEST_INPUT_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const PretestTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETEST_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETEST_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRETEST_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRETEST_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_PRETEST_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRETEST_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_PRETEST_TEMPLATE_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const PretestPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETEST_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRETEST_PUBLISHED_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRETEST_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const FaxCoverPageTemplates = {
    get: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRINT_TEMPLATES_API_URI}/fax-cover-page`, cancelCallback);
    },
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRINT_TEMPLATES_API_URI}/fax-cover-page`, option, cancelCallback);
    },
}

const InvoiceTemplates = {
    get: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRINT_TEMPLATES_API_URI}/invoice`, cancelCallback);
    },
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRINT_TEMPLATES_API_URI}/invoice`, option, cancelCallback);
    },
}

const PaymentReceiptTemplates = {
    get: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRINT_TEMPLATES_API_URI}/payment-receipt`, cancelCallback);
    },
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRINT_TEMPLATES_API_URI}/payment-receipt`, option, cancelCallback);
    },
}

const PrintPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRINT_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRINT_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const Organizations = {
    idp: (subdomain, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_ORGANIZATIONS_API_URI}/${subdomain}/idp`, cancelCallback);
    },
    subdomain: (email, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_ORGANIZATIONS_API_URI}/${email}/subdomain`, cancelCallback);
    },
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_ORGANIZATIONS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_ORGANIZATIONS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_ORGANIZATIONS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_ORGANIZATIONS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_ORGANIZATIONS_API_URI}/${id}`, null, cancelCallback);
    }
}

const Tenants = {
    get: (id, loadFullProperties, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TENANTS_API_URI}/${id}${loadFullProperties === true ? '?loadFullProperties=true' : ''}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TENANTS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TENANTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_TENANTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_TENANTS_API_URI}/${id}`, null, cancelCallback);
    },
    accessKey: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TENANTS_API_URI}/${id}/access-key`, cancelCallback);
    },
}

const Invoices = {
    print: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INVOICES_API_URI}/print`, option, cancelCallback)
    }
}

const PrintedDocuments = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PRINTED_DOCUMENTS_API_URI}/${id}`, cancelCallback);
    },
    print: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRINTED_DOCUMENTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_PRINTED_DOCUMENTS_API_URI}/${id}`, option, cancelCallback);
    },
    download: (id, cancelCallback) => {
        return api.file(`${process.env.REACT_APP_PRINTED_DOCUMENTS_API_URI}/${id}/download`, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PRINTED_DOCUMENT_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const PreviewDocuments = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PREVIEW_DOCUMENTS_API_URI}/${id}`, cancelCallback);
    },
    print: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PREVIEW_DOCUMENTS_API_URI}`, option, cancelCallback);
    },
    download: (id, cancelCallback) => {
        return api.file(`${process.env.REACT_APP_PREVIEW_DOCUMENTS_API_URI}/${id}/download`, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PREVIEW_DOCUMENT_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Communications = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_COMMUNICATIONS_API_URI}/${id}`, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_COMMUNICATIONS_API_URI}/${id}`, option, cancelCallback);
    },
    send: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATIONS_API_URI}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_COMMUNICATIONS_API_URI}/${id}`, null, cancelCallback);
    },
    resend: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATIONS_API_URI}/${id}/resend`, option, cancelCallback);
    },
    hide: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATIONS_API_URI}/${id}/hide`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATION_SEARCH_API_URI}`, option, cancelCallback);
    },
    download: (id, cancelCallback) => {
        return api.file(`${process.env.REACT_APP_COMMUNICATIONS_API_URI}/${id}/download`, cancelCallback);
    },
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATION_LIST_API_URI}`, option, cancelCallback);
    },
    statusCount: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATION_LIST_API_URI}/status-count`, option, cancelCallback);
    },
}

const CommunicationTemplates = {
    get: (id, full, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_COMMUNICATION_TEMPLATES_API_URI}/${id}${!!full ? '?full=true' : ''}`, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_COMMUNICATION_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    fill: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATION_TEMPLATES_API_URI}/${id}/fill`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATION_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, showAll, showResult, showCount, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showResult: showResult,
            showCount: showCount,
        });
        return api.get(`${process.env.REACT_APP_COMMUNICATION_TEMPLATE_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const CommunicationPreferences = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_COMMUNICATION_PREFERENCES_API_URI}/${id}`, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_COMMUNICATION_PREFERENCES_API_URI}/${id}`, option, cancelCallback);
    },
}

const CommunicationSuppressions = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_COMMUNICATION_SUPPRESSIONS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATION_SUPPRESSIONS_API_URI}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_COMMUNICATION_SUPPRESSIONS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_COMMUNICATION_SUPPRESSION_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const ExternalClinics = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXTERNAL_CLINICS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXTERNAL_CLINICS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_EXTERNAL_CLINICS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_EXTERNAL_CLINICS_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXTERNAL_CLINIC_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, showAll, showResult, showCount, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showResult: showResult,
            showCount: showCount,
        });
        return api.get(`${process.env.REACT_APP_EXTERNAL_CLINIC_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const ExternalDoctors = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_EXTERNAL_DOCTORS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXTERNAL_DOCTORS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_EXTERNAL_DOCTORS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_EXTERNAL_DOCTORS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_EXTERNAL_DOCTOR_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, showAll, showResult, showCount, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showResult: showResult,
            showCount: showCount,
        });
        return api.get(`${process.env.REACT_APP_EXTERNAL_DOCTOR_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const FormInputs = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_FORM_INPUTS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_FORM_INPUTS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_FORM_INPUTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_FORM_INPUTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_FORM_INPUTS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_FORM_INPUT_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_FORM_INPUT_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const FormTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_FORM_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_FORM_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_FORM_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_FORM_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_FORM_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    clone: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_FORM_TEMPLATES_API_URI}/${id}/clone`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_FORM_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_FORM_TEMPLATE_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const FormPublishedTemplates = {
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_FORM_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const Forms = {
    get: (publicKey, id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_FORMS_API_URI}/${publicKey}${id ? `?id=${id}` : ''}`, cancelCallback);
    },
    create: (publicKey, option, cancelCallback, key, token) => {
        return api.post(`${process.env.REACT_APP_FORMS_API_URI}/${publicKey}`, option, cancelCallback, key, token);
    },
    update: (publicKey, id, option, cancelCallback, key, token) => {
        return api.put(`${process.env.REACT_APP_FORMS_API_URI}/${publicKey}${id ? `?id=${id}` : ''}`, option, cancelCallback, key, token);
    },
    complete: (publicKey, id, option, cancelCallback, key, token) => {
        return api.post(`${process.env.REACT_APP_FORMS_API_URI}/${publicKey}/complete${id ? `?id=${id}` : ''}`, option, cancelCallback, key, token);
    },
    email: (publicKey, id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_FORMS_API_URI}/${publicKey}/email${id ? `?id=${id}` : ''}`, null, cancelCallback);
    },
}

const InternalFormInputs = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INTERNAL_FORM_INPUTS_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INTERNAL_FORM_INPUTS_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORM_INPUTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_INTERNAL_FORM_INPUTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_INTERNAL_FORM_INPUTS_API_URI}/${id}`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORM_INPUT_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_INTERNAL_FORM_INPUT_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const InternalFormTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INTERNAL_FORM_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INTERNAL_FORM_TEMPLATES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORM_TEMPLATES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_INTERNAL_FORM_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_INTERNAL_FORM_TEMPLATES_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORM_TEMPLATE_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, limit, offset, count, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            limit: limit,
            offset: offset,
            count: count,
        });
        return api.get(`${process.env.REACT_APP_INTERNAL_FORM_TEMPLATE_SEARCH_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const InternalFormPublishedTemplates = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INTERNAL_FORM_PUBLISHED_TEMPLATES_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORM_PUBLISHED_TEMPLATES_API_URI}`, option, cancelCallback);
    },
}

const InternalForms = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INTERNAL_FORMS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORMS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_INTERNAL_FORMS_API_URI}/${id}`, option, cancelCallback);
    },
    complete: (id, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORMS_API_URI}/${id}/complete`, option, cancelCallback);
    },
    sign: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORMS_API_URI}/${id}/sign`, null, cancelCallback);
    },
    unSign: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_INTERNAL_FORMS_API_URI}/${id}/un-sign`, null, cancelCallback);
    },
}

const AddressBook = {
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_ADDRESS_BOOK_SEARCH_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, types, limit, offset, showAll, showResult, showCount, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            types: (types && types.length > 0 ? types.join('|') : []),
            limit: limit,
            offset: offset,
            showAll: showAll,
            showResult: showResult,
            showCount: showCount,
        });
        return api.get(`${process.env.REACT_APP_ADDRESS_BOOK_LOOKUP_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const AddressBookLookUp = {
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_ADDRESS_BOOK_LOOKUP_API_URI}`, option, cancelCallback);
    },
    fullSearch: (term, type, contactType, customerId, limit, offset, showAll, showResult, showCount, cancelCallback) => {
        const qs = queryString.stringify({
            term: term,
            type: type,
            contactType: contactType,
            customerId: customerId,
            limit: limit,
            offset: offset,
            showAll: showAll,
            showResult: showResult,
            showCount: showCount,
        });
        return api.get(`${process.env.REACT_APP_ADDRESS_BOOK_LOOKUP_API_URI}/${encodeURIComponent(term)}${qs ? `?${qs}` : ''}`, cancelCallback);
    }
}

const Lockscreen = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_LOCKSCREEN_API_URI}/${id}`, cancelCallback);
    },
    activate: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_LOCKSCREEN_API_URI}/${id}`, null, cancelCallback);
    },
    deactivate: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_LOCKSCREEN_API_URI}/${id}`, null, cancelCallback);
    },
}

const LogicalDevices = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_LOGICAL_DEVICES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_LOGICAL_DEVICES_API_URI}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_LOGICAL_DEVICES_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_LOGICAL_DEVICES_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_LOGICAL_DEVICES_API_URI}/${id}`, option, cancelCallback);
    },
    parseDeviceData: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_DEVICE_WEBHOOK_URI}`, option, cancelCallback);
    },
    composeDeviceData: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_DEVICE_WEBHOOK_URI}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_LOGICAL_DEVICE_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const SpecialDates = {
    get: (start, end, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SPECIAL_DATES_API_URI}/${start}${end ? `/${end}` : ''}`, cancelCallback);
    }
}

const TimeOffs = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TIMEOFF_REQUESTS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TIMEOFF_REQUESTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_TIMEOFF_REQUESTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_TIMEOFF_REQUESTS_API_URI}/${id}`, null, cancelCallback);
    },
    approve: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TIMEOFF_REQUESTS_API_URI}/${id}/approve`, null, cancelCallback);
    },
    decline: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TIMEOFF_REQUESTS_API_URI}/${id}/decline`, null, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_TIMEOFF_REQUEST_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Subscription = {
    checkout: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SUBSCRIPTION_API_URI}/checkout`, option, cancelCallback);
    },
    availableAddOn: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SUBSCRIPTION_API_URI}/available_addon`, cancelCallback);
    },
    get: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SUBSCRIPTION_API_URI}`, cancelCallback);
    },
    update: (option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_SUBSCRIPTION_API_URI}`, option, cancelCallback);
    },
    createPaymentSetupSession: (customerId, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SUBSCRIPTION_API_URI}/create-payment-setup/${customerId}`, null, cancelCallback);
    },
}

const Reports = {
    _legacy: {
        PaymentTypeFee: {
            get: (cancelCallback) => {
                return api.get(`${process.env.REACT_APP_REPORT_PAYMENT_TYPE_FEE_URI}`, cancelCallback);
            },
        },
        SalesTransaction: {
            get: (cancelCallback) => {
                return api.get(`${process.env.REACT_APP_REPORT_SALES_TRANSACTION}`, cancelCallback);
            },
        },
        Utilization: {
            get: (cancelCallback) => {
                return api.get(`${process.env.REACT_APP_REPORT_UTILIZATION}`, cancelCallback);
            },
        },
        SalesPerVisit: {
            get: (periodType, cancelCallback) => {
                return api.get(`${process.env.REACT_APP_REPORT_SALES_PER_VISIT}/${periodType}`, cancelCallback);
            },
        },
        Revenue: {
            get: (periodType, cancelCallback) => {
                return api.get(`${process.env.REACT_APP_REPORT_REVENUE}/${periodType}`, cancelCallback);
            },
        },
        SalesByAgeGroup: {
            search: (groups, cancelCallback) => {
                return api.post(`${process.env.REACT_APP_REPORT_SALES_BY_AGE_GROUP}`, groups, cancelCallback);
            }
        },
        DashboardReport: {
            series: (options, cancelCallback) => {
                return api.post(`${process.env.REACT_APP_REPORT_DASHBOARD_SERIES}`, options, cancelCallback);
            },
            single: (options, cancelCallback) => {
                return api.post(`${process.env.REACT_APP_REPORT_DASHBOARD_SINGLE}`, options, cancelCallback);
            },
        }
    },
    CustomerLocation: {
        get: (cancelCallback) => {
            return api.get(`${process.env.REACT_APP_REPORT_CUSTOMERS_DISTRIBUTION}`, cancelCallback);
        },
    },
    SalesRevenueBreakdown: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_SALES_REVENUE_BREAKDOWN}`, options, cancelCallback);
        }
    },
    SalesRevenueProductBreakdown: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_GROSS_PRODUCT_REVENUE_BREAKDOWN}`, options, cancelCallback);
        }
    },
    ExamSalesConversionRate: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_EXAM_PRODUCT_SALES_CONVERSION_RATE}`, options, cancelCallback);
        }
    },
    ExamSalesRevenue: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_EXAM_SALES_REVENUE}`, options, cancelCallback);
        }
    },
    CustomerAcquisition: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_CUSTOMER_ACQUISITION}`, options, cancelCallback);
        }
    },
    AppointmentStatusBreakdown: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_APPOINTMENT_STATUS_BREAKDOWN}`, options, cancelCallback);
        }
    },
    AppointmentArrivalTime: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_APPOINTMENT_ARRIVAL_TIME}`, options, cancelCallback);
        }
    },
    ExamStatusBreakdown: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_EXAM_STATUS_BREAKDOWN}`, options, cancelCallback);
        }
    },
    CustomerDemographics: {
        search: (cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_CUSTOMER_DEMOGRAPHICS}`, {}, cancelCallback);
        }
    },
    ScheduleUtilization: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_SCHEDULE_HEALTH_UTILIZATION}`, options, cancelCallback);
        }
    },
    SupplierSales: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_SUPPLIER_SALES}`, options, cancelCallback);
        }
    },
    CommunicationUsage: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_COMMUNICATION_USAGE}`, options, cancelCallback);
        },
    },
    ProductSales: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_PRODUCT_SALES}`, options, cancelCallback);
        }
    },
    CashFlow: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_CASH_FLOW}`, options, cancelCallback);
        }
    },
    CustomerExamInterval: {
        search: (options, cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_CUSTOMER_EXAM_INTERVAL}`, options, cancelCallback);
        }
    },
    InvoiceAgingSummary: {
        search: (cancelCallback) => {
            return api.post(`${process.env.REACT_APP_REPORT_INVOICE_AGING_SUMMARY}`, {}, cancelCallback);
        }
    }
}

const Industries = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INDUSTRIES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INDUSTRIES_API_URI}`, cancelCallback);
    },
}

const IndustryDevices = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INDUSTRY_DEVICES_API_URI}/${id}`, cancelCallback);
    },
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_INDUSTRY_DEVICES_API_URI}`, cancelCallback);
    },
}

const GovernmentPrograms = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_GOVERNMENT_PROGRAMS_API_URI}/${id}`, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_GOVERNMENT_PROGRAM_SEARCH_API_URI}`, option, cancelCallback);
    },
}

const Contacts = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_CONTACTS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_CONTACTS_API_URI}`, option, cancelCallback);
    },
    update: (id, option, cancelCallback) => {
        return api.put(`${process.env.REACT_APP_CONTACTS_API_URI}/${id}`, option, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_CONTACTS_API_URI}/${id}`, option, cancelCallback);
    },
}

const PublicInsuranceBillingReports = {
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PUBLIC_INSURANCE_LIST_API_URI}`, option, cancelCallback);
    },
    exportList: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_PUBLIC_INSURANCE_LIST_API_URI}/export`, option, cancelCallback);
    },
}

const EndOfDaySnapshot = {
    get: (date, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_END_OF_DAY_SNAPSHOT_API_URI}/${date}`, cancelCallback);
    },
    save: (date, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_END_OF_DAY_SNAPSHOT_API_URI}/${date}`, option, cancelCallback);
    },
    delete: (date, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_END_OF_DAY_SNAPSHOT_API_URI}/${date}`, cancelCallback);
    },
    reports: (date, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_END_OF_DAY_SNAPSHOT_API_URI}/${date}/reports`, null, cancelCallback);
    },
    recalls: (date, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_END_OF_DAY_SNAPSHOT_API_URI}/${date}/recalls`, option, cancelCallback);
    },
    reviewRequests: (date, option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_END_OF_DAY_SNAPSHOT_API_URI}/${date}/review-requests`, option, cancelCallback);
    },
    exists: (date, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_END_OF_DAY_SNAPSHOT_API_URI}/${date}/exists`, cancelCallback);
    },
}

const Batch = {
    recall: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_BATCH_API_URI}/recall`, option, cancelCallback);
    },
    relationships: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_BATCH_API_URI}/relationships`, option, cancelCallback);
    },
    todo: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_BATCH_API_URI}/todo`, option, cancelCallback);
    },
}

const BookingTypes = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_BOOKING_TYPES_API_URI}`, cancelCallback);
    },
}
const Bookings = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_BOOKING_API_URI}/${id}`, cancelCallback);
    },
    delete: (id, option, cancelCallback) => {
        return api.delete(`${process.env.REACT_APP_BOOKING_API_URI}/${id}`, option, cancelCallback);
    },
    search: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_BOOKING_SEARCH_API_URI}`, option, cancelCallback);
    }
}

const Timeslots = {
    search: (start, end, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_TIMESLOT_SEARCH_API_URI}/${start}/${end}`, cancelCallback);
    }
}

const AppointmentOffers = {
    get: (id, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_APPOINTMENT_OFFERS_API_URI}/${id}`, cancelCallback);
    },
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_APPOINTMENT_OFFERS_API_URI}`, option, cancelCallback);
    },
    clipboard: (id, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_APPOINTMENT_OFFERS_API_URI}/${id}`, null, cancelCallback);
    },
}

const SecuredDownload = {
    check: (key, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_SECURED_DOWNLOAD_API_URI}/${key}`, null, cancelCallback);
    },
    download: (key, cancelCallback) => {
        return api.file(`${process.env.REACT_APP_SECURED_DOWNLOAD_API_URI}/${key}`, cancelCallback);
    }
}

const Migrate = {
    paperExam: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_MIGRATE_API_URI}/paper-exam`, option, cancelCallback);
    },
}

const DeviceSyncFiles = {
    list: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_DEVICESYNC_FILE_LIST_API_URI}`, option, cancelCallback);
    }
}

const SystemSettings = {
    all: (cancelCallback) => {
        return api.get(`${process.env.REACT_APP_SYSTEM_SETTINGS_API_URI}`, cancelCallback);
    }
}

const ApplicationLogs = {
    create: (option, cancelCallback) => {
        return api.post(`${process.env.REACT_APP_APPLICATION_LOG_API_URI}`, option, cancelCallback);
    }
}

const Passcode = {
    request: (username, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PASSCODE_CHALLENGE_API_URI}/request?username=${username}`, cancelCallback);
    },
    verify: (code, username, cancelCallback) => {
        return api.get(`${process.env.REACT_APP_PASSCODE_CHALLENGE_API_URI}/verify?code=${code}&userName=${username}`, cancelCallback);
    }
}

const Test = {
    get: () => {
        return api.get('v1/_init/test-az-user');
    }
}

export default {
    Accesses,
    AddressBook,
    AddressBookLookUp,
    ApplicationLogs,
    AppointmentOffers,
    Appointments,
    Attachments,
    Authorize,
    Batch,
    BookingTypes,
    Bookings,
    BusinessDays,
    BusinessHours,
    BusinessSchedules,
    CommunicationPreferences,
    CommunicationSuppressions,
    CommunicationTemplates,
    Communications,
    Contacts,
    CustomerPrivateInsurances,
    CustomerRelationships,
    Customers,
    DeviceSyncFiles,
    EndOfDaySnapshot,
    EntityAlerts,
    ExamDatasetCategories,
    ExamDatasetGroups,
    ExamDatasets,
    ExamInputs,
    ExamPublishedTemplates,
    ExamTemplates,
    Exams,
    ExternalClinics,
    ExternalDoctors,
    FaxCoverPageTemplates,
    FormInputs,
    FormPublishedTemplates,
    FormTemplates,
    Forms,
    GovernmentPrograms,
    GroupAppointments,
    Groups,
    Industries,
    IndustryDevices,
    InternalFormInputs,
    InternalFormPublishedTemplates,
    InternalFormTemplates,
    InternalForms,
    Inventory,
    InvoiceTemplates,
    Invoices,
    Lockscreen,
    LogicalDevices,
    MedicalReportPublishedTemplates,
    MedicalReportResponses,
    MedicalReportTemplates,
    MedicalReportTenantExamDatasetGroups,
    MedicalReportUserExamDatasetGroups,
    MedicalReports,
    Migrate,
    Notes,
    Notifications,
    OrganizationUsers,
    Organizations,
    Passcode,
    PatientProfiles,
    PatientRecalls,
    PaymentReceiptTemplates,
    Payments,
    PredefinedData,
    PrescriptionInputs,
    PrescriptionPrintPublishedTemplates,
    PrescriptionPrintTemplates,
    PrescriptionPublishedTemplates,
    PrescriptionTemplates,
    PrescriptionTypes,
    Prescriptions,
    PretestInputs,
    PretestPublishedTemplates,
    PretestTemplates,
    PretestTypes,
    Pretests,
    PreviewDocuments,
    PrintPublishedTemplates,
    PrintedDocuments,
    PrivateInsuranceProviders,
    ProductBrands,
    ProductOptions,
    ProductSuppliers,
    ProductTypes,
    Products,
    PublicInsuranceBillingReports,
    Purchases,
    ReferenceData,
    ReferralAppointments,
    ReferralPublishedTemplates,
    ReferralTemplates,
    ReferralTenantExamDatasetGroups,
    ReferralUserExamDatasetGroups,
    Referrals,
    Reports,
    Resources,
    ReviewFeedbacks,
    ReviewRequests,
    Roles,
    Schedules,
    SecuredDownload,
    ServiceAttributes,
    ServiceEligibility,
    ServiceUserOverrides,
    Services,
    Settings,
    SpecialDates,
    Subscription,
    SystemSettings,
    TaxRates,
    TenantUsers,
    Tenants,
    Test,
    TimeOffs,
    TimelineItems,
    TimeslotSchedules,
    Timeslots,
    TodoInstances,
    TodoNotes,
    Todos,
    TransactionItems,
    TransactionPayments,
    TransactionSales,
    Transactions,
    Users,
    WorkOrderInputs,
    WorkOrderItems,
    WorkOrderPublishedTemplates,
    WorkOrderTemplates,
    WorkOrders,
}

