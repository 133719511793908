import React, { useEffect, useContext, useRef } from 'react';
import FadeIn from 'react-fade-in';
import { Observer } from 'mobx-react-lite';
import { GlobalHotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';
import moment from 'moment';

import { quickDrawerFocus, renderQuickDrawerLoading } from '../../_shared/QuickDrawer';
import QuickDrawerHeader from '../../_shared/QuickDrawerHeader';

import PurchaseViewStore from '../../../../stores/PurchaseViewStore';
import PurchaseUpdateStore from '../../../../stores/PurchaseUpdateStore';
import QuickDrawerStore from '../../../../stores/QuickDrawerStore';

import * as fn from '../../../../utilities/_functions';
import * as ih from '../../../../utilities/invoiceHelper';
import * as uh from '../../../../utilities/userHelper';
import * as oh from '../../../../utilities/operationHelper';
import * as bh from '../../../../utilities/badgeHelper';

import './PaymentHistory.scss';

function PaymentHistory(props) {
    const isMounted = useRef(true);
    const focusTimer = useRef(null);
    const purchase = useContext(PurchaseViewStore);
    const updatePurchase = useContext(PurchaseUpdateStore);
    const quickDrawer = useContext(QuickDrawerStore);

    useEffect(() => {
        focusTimer.current = setTimeout(() => {
            quickDrawerFocus(props.drawer);
        }, 100)
        return () => {
            isMounted.current = false;
            if (focusTimer.current) { clearTimeout(focusTimer.current); focusTimer.current = null; }
        }
    }, []) // eslint-disable-line

    const handlePayment = () => {
        updatePurchase.load(purchase.id);
        quickDrawer.activateQuickDrawer('purchase', 'payment', null, handlePaymentSuccess, handlePaymentCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            })
    }

    const handlePaymentSuccess = () => {
        purchase.reload()
            .then(() => {
                if (isMounted.current) {
                    updatePurchase.clear();
                    toast.dark(() => <p data-pymt-ctd>Payment created.</p>);
                }
            })
    }

    const handlePaymentCancel = () => {
        updatePurchase.clear();
    }

    const handleRefundPayment = () => {
        updatePurchase.load(purchase.id);
        quickDrawer.activateQuickDrawer('purchase', 'refund-payment', null, handleRefundSuccess, handleRefundCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleRefundSuccess = (result) => {
        if (result && result.updated) {
            toast.dark(() => <p data-pymt-refunded>Payment refunded.</p>);

            purchase.reload()
                .then(() => {
                    if (isMounted.current) {
                        updatePurchase.clear();
                        quickDrawer.deactivateAll();

                        if (fn.isFunction(props.onCancel)) {
                            props.onCancel();
                        }
                    }
                })
        }
    }

    const handleRefundCancel = () => {
        updatePurchase.clear();
    }

    const handleCollectThisClick = (event, { id, insuranceInformation, governmentProgramInformation, paymentMethod }) => {
        if (paymentMethod) {
            updatePurchase.load(purchase.id);

            if (paymentMethod.toLowerCase() === 'privateinsurance') {
                quickDrawer.activateQuickDrawer('purchase', 'private-insurance-payment', { id: id, data: insuranceInformation }, handleCollectThisSuccess, handleCollectThisCancel)
                    .then(drawer => {
                        if (isMounted.current) {
                            focusTimer.current = setTimeout(() => {
                                quickDrawerFocus(drawer);
                            }, 100);
                        }
                    });
            }

            if (paymentMethod.toLowerCase() === 'governmentprogram') {
                quickDrawer.activateQuickDrawer('purchase', 'government-program-payment', { id: id, data: governmentProgramInformation }, handleCollectThisSuccess, handleCollectThisCancel)
                    .then(drawer => {
                        if (isMounted.current) {
                            focusTimer.current = setTimeout(() => {
                                quickDrawerFocus(drawer);
                            }, 100);
                        }
                    });
            }
        }
    }

    const handleCollectThisSuccess = () => {
        purchase.reload()
            .then(() => {
                if (isMounted.current) {
                    toast.dark(() => <p data-pymt-upd>Payment updated.</p>);
                    updatePurchase.clear();
                    quickDrawer.deactivateAll();

                    if (fn.isFunction(props.onCancel)) {
                        props.onCancel();
                    }
                }
            })
    }

    const handleCollectThisCancel = () => {
        updatePurchase.clear();
    }

    const handleDeletePayment = (event, { id, amount, paymentMethod }) => {
        updatePurchase.load(purchase.id);
        quickDrawer.activateQuickDrawer('purchase', 'delete-payment', { id, amount, paymentMethod }, handleDeletePaymentSuccess, handleDeletePaymentCancel)
            .then(drawer => {
                if (isMounted.current) {
                    focusTimer.current = setTimeout(() => {
                        quickDrawerFocus(drawer);
                    }, 100);
                }
            });
    }

    const handleDeletePaymentSuccess = () => {
        purchase.reload()
            .then(() => {
                if (isMounted.current) {
                    toast.dark(() => <p data-pymt-upd>Payment updated.</p>);
                    updatePurchase.clear();
                    quickDrawer.deactivateAll();

                    if (fn.isFunction(props.onCancel)) {
                        props.onCancel();
                    }
                }
            })
    }

    const handleDeletePaymentCancel = () => {
        updatePurchase.clear();
    }

    return <>
        <Observer>{() =>
            <>
                {
                    (props.drawer === quickDrawer.drawerOpened) ?
                        <GlobalHotKeys
                            keyMap={{
                                close: ['esc'],
                            }}
                            handlers={{
                                close: event => {
                                    props.onCancel(event)
                                },
                            }}
                            allowChanges={true}
                        /> : null
                }
            </>
        }</Observer>
        <form>
            <fieldset>
                <div className='payment-history-container quick-drawer'>
                    <QuickDrawerHeader
                        drawer={props.drawer}
                        icon={oh.getIcon('purchase', 'history')}
                        action='Purchase'
                        category='Payment History'
                        className='purchases'
                        onCancel={props.onCancel}
                    />
                    <div className='quick-drawer-body'>
                        <Observer>{() =>
                            <>
                                {
                                    purchase.data ?
                                        <FadeIn>
                                            <div className='body-content'>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <h3 className='purchase-title'>Invoice Detail</h3>
                                                            <p>Invoice # <strong>{purchase.data.invoiceNumber}</strong></p>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='text-right'>
                                                                {bh.renderPurchaseBalanceStatus(purchase.data, 'text-truncate text-truncate-md fs-sm p-2')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            {
                                                                purchase.data.payments && purchase.data.payments.length > 0 ?
                                                                    purchase.data.payments.map((p, pi) => {
                                                                        return <div key={`payment-history-${pi}`} className={'d-flex flex-column align-items-center'}>
                                                                            <div className='d-flex flex-row w-100 pb-4'>
                                                                                {
                                                                                    p.paymentMethod && !p.refundMethod ?
                                                                                        <>
                                                                                            <div className='d-inline-block align-middle mr-3'>
                                                                                                <span className='d-block mt-1'>
                                                                                                    {fn.toPaymentIcon(p.paymentMethod, 'fs-xxl')}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='mb-0 flex-1 text-dark'>
                                                                                                <div className='d-flex'>
                                                                                                    {
                                                                                                        p.paymentMethod && ['privateinsurance', 'governmentprogram'].some(m => p.paymentMethod.toLowerCase() === m) && p.isToBeCollected ?
                                                                                                            <>
                                                                                                                <div>
                                                                                                                    <span className='d-block text-truncate text-truncate-md text-gray-800 fw-500'>{ih.getPendingPaymentDescription(p)}</span>
                                                                                                                    <div className='d-block text-muted fs-80'>
                                                                                                                        Created by <span className='text-gray-700 fw-500'>{uh.getDisplayShortNameById(p.createdById, p.createdBy)}</span>
                                                                                                                        <br />
                                                                                                                        {fn.formatFullDateWithOrWithoutYear(moment.utc(p.createdDateUtc).local())}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='fs-xl pt-o ml-auto text-right'>
                                                                                                                    {fn.formatCurrency(p.amount)}
                                                                                                                    <div>
                                                                                                                        <button
                                                                                                                            type='button'
                                                                                                                            className='btn btn-sm btn-link text-right p-0 text-success text-success-800-hover'
                                                                                                                            onClick={e => { handleCollectThisClick(e, p) }}
                                                                                                                        >
                                                                                                                            Mark as collected
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </> :
                                                                                                            <>
                                                                                                                <div>
                                                                                                                    {fn.toPaymentDescription(p, 'text-gray-800 fw-500')}
                                                                                                                    <div className='d-block text-muted fs-80'>
                                                                                                                        Taken by <span className='text-gray-700 fw-500'>{uh.getDisplayShortNameById(p.createdById, p.createdBy)}</span>
                                                                                                                        <br />
                                                                                                                        {fn.formatFullDateWithOrWithoutYear(moment(p.paymentDate))}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className='fs-xl pt-o ml-auto text-right'>
                                                                                                                    {fn.formatCurrency(p.amount)}
                                                                                                                </div>
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    p.note ?
                                                                                                        <div className='mt-2 fs-80'>
                                                                                                            <div className='html' dangerouslySetInnerHTML={{ __html: p.note.bodyHtml }}></div>
                                                                                                        </div> : null
                                                                                                }
                                                                                            </div>
                                                                                        </> : null
                                                                                }
                                                                                {
                                                                                    !p.paymentMethod && p.refundMethod ?
                                                                                        <>
                                                                                            <div className='d-inline-block align-middle mr-3'>
                                                                                                <span className='d-block mt-1'>
                                                                                                    {fn.toPaymentIcon(p.refundMethod, 'text-danger fs-xxl')}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='mb-0 flex-1 text-danger'>
                                                                                                <div className='d-flex'>
                                                                                                    <div>
                                                                                                        {fn.toRefundDescription(p.refundMethod, 'text-danger-800 fw-500')}
                                                                                                        <div className='d-block text-muted fs-80'>
                                                                                                            Refunded by <span className='text-gray-700 fw-500'>{uh.getDisplayShortNameById(p.createdById, p.createdBy)}</span>
                                                                                                            <br />
                                                                                                            {fn.formatFullDateWithOrWithoutYear(moment.utc(p.createdDateUtc).local())}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='fs-xl pt-o ml-auto'>
                                                                                                        {fn.formatCurrency(p.amount)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    p.note ?
                                                                                                        <div className='mt-2 fs-80'>
                                                                                                            <div className='html' dangerouslySetInnerHTML={{ __html: p.note.bodyHtml }}></div>
                                                                                                        </div> : null
                                                                                                }
                                                                                            </div>
                                                                                        </> : null
                                                                                }
                                                                                {
                                                                                    !purchase.data.isNoCharge && !purchase.data.isUncollectible && purchase.data.status !== 'Refunded' && purchase.data.status !== 'Voided' && purchase.data.status !== 'Uncollectible' && !purchase.data.refundPurchaseId ?
                                                                                        <div className='pl-o'>
                                                                                            <button
                                                                                                type='button'
                                                                                                className='btn btn-icon line-height-1 mr-n2'
                                                                                                title='Delete payment'
                                                                                                onClick={e => { handleDeletePayment(e, p) }}
                                                                                            >
                                                                                                <i className='fal fa-times text-danger fs-lg'></i>
                                                                                            </button>
                                                                                        </div> : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </FadeIn> : renderQuickDrawerLoading()
                                }
                            </>
                        }</Observer>
                    </div>
                    <div className='quick-drawer-action'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='float-right'>
                                    {
                                        purchase.data && (['Refunded', 'Voided', 'Deleted'].some(s => purchase.data.status === s) || !!purchase.data.refundPurchaseId) ?
                                            <button
                                                type='button'
                                                className='btn btn-link btn-cancel mr-n2'
                                                onClick={props.onCancel}
                                            >Close</button> :
                                            <>
                                                {
                                                    purchase.data && purchase.data.status === 'Completed' ?
                                                        <>
                                                            {
                                                                purchase.data.remainingBalance < purchase.data.total ?
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-danger ml-2'
                                                                        onClick={handleRefundPayment}
                                                                    >Refund</button> : null
                                                            }
                                                            {
                                                                purchase.data.remainingBalance > 0 ?
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-alt ml-2'
                                                                        onClick={handlePayment}
                                                                    >Payment</button> : null
                                                            }
                                                        </> : null
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </>
}

export default PaymentHistory;