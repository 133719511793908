import moment, { isMoment } from 'moment';
import { hasNumericValue } from 'mathjs';

import * as ph from './personHelper';

export const NAME_FORMAT_OPTIONS = [{
    type: 'FirstLastName',
    example: 'Jonathan Williams',
}, {
    type: 'PreferredFirstLastName',
    example: 'John (Jonathan) Williams',
}, {
    type: 'LastFirstName',
    example: 'Williams, Jonathan',
}, {
    type: 'LastFirstMiddleName',
    example: 'Williams, Jonathan Isaac',
}, {
    type: 'PreferredLastFirstName',
    example: 'Williams, John (Jonathan)',
}, {
    type: 'PreferredLastFirstMiddleName',
    example: 'Williams, John Isaac (Jonathan)',
}];

export const DOCTOR_NAME_FORMAT_OPTIONS = [{
    type: 'DrFirstLastNameNoSalutation',
    example: 'Jonathan Williams',
},
{
    type: 'DrFirstLastName',
    example: 'Dr. John Williams',
},
{
    type: 'DrFirstLastNameDesignationNoSalutation',
    example: 'John Williams, MD',
},
{
    type: 'DrFirstLastNameDesignation',
    example: 'Dr. John Williams, MD',
}];

export const DOCTOR_LICENSE_BILLING_FORMAT_OPTIONS = [{
    type: 'LicenseOnly',
    example: 'License No.: 012345',
},
{
    type: 'BillingOnly',
    example: 'Billing No.: 678901',
},
{
    type: 'LicenseBilling',
    example: 'License No.: 012345, Billing No.: 678901',
},
{
    type: 'NoLicenseBilling',
    example: '[empty]',
}];

export const PHONE_FORMAT_OPTIONS = [{
    type: 'PhoneInternational',
    example: '+14165559999'
}, {
    type: 'PhoneSpace',
    example: '416 555 9999',
}, {
    type: 'PhoneDash',
    example: '416-555-9999',
}, {
    type: 'PhoneBrackets',
    example: '(416) 555-9999',
}, {
    type: 'PhoneSpacePlusOne',
    example: '1 416 555 9999',
}, {
    type: 'PhoneDashPlusOne',
    example: '1-416-555-9999',
}, {
    type: 'PhoneBracketsPlusOne',
    example: '1 (416) 555-9999',
}];

export const DATE_FORMAT_OPTIONS = [{
    type: 'DashYearMonthDay',
    example: moment().format('YYYY-MM-DD'),
}, {
    type: 'SlashYearMonthDay',
    example: moment().format('YYYY/MM/DD'),
}, {
    type: 'FullDate',
    example: moment().format('MMMM D, YYYY'),
}, {
    type: 'ShortDate',
    example: moment().format('MMM D, YYYY'),
}];

export const PAGE_NUMBER_FORMAT_OPTIONS = [{
    type: 'PageNumberOnly',
    example: '1',
}, {
    type: 'PrefixPageNumberOnly',
    example: 'Page 1',
}, {
    type: 'PageNumberAndTotal',
    example: '1 of 1',
}, {
    type: 'PrefixPageNumberAndTotal',
    example: 'Page 1 of 1',
}];

export const DISPLAY_STRING_OPTIONS = [{
    type: 'None',
    example: 'Display as is',
}, {
    type: 'PlusMinus',
    example: '+1.00, -1.00, 0.00',
}, {
    type: 'PlusMinusPlusZero',
    example: '+1.00, -1.00, +0.00',
}];

export const apply = (obj, format, defaultValue) => {
    if (!obj || !format || !format) {
        return defaultValue ? defaultValue : obj;
    }

    switch (format) {
        case 'FirstLastName':
        case 'PreferredFirstLastName':
        case 'LastFirstName':
        case 'LastFirstMiddleName':
        case 'PreferredLastFirstName':
        case 'PreferredLastFirstMiddleName':
        case 'FirstLastNameNoSalutation':
        case 'DrFirstLastNameNoSalutation':
        case 'DrFirstLastName':
        case 'DrFirstLastNameDesignationNoSalutation':
        case 'DrFirstLastNameDesignation':
            switch (format) {
                case 'FirstLastName':
                    return ph.getFirstLastName(obj);

                case 'PreferredFirstLastName':
                    return ph.getPreferredFirstLastName(obj);

                case 'LastFirstName':
                    return ph.getLastFirstName(obj);

                case 'LastFirstMiddleName':
                    return ph.getLastFirstMiddleName(obj);

                case 'PreferredLastFirstName':
                    return ph.getLastFirstName(obj);

                case 'PreferredLastFirstMiddleName':
                    return ph.getPreferredLastFirstMiddleName(obj);

                case 'DrFirstLastNameNoSalutation':
                    obj.salutation = null;
                    return ph.getFirstLastName(obj);

                case 'DrFirstLastName':
                    return ph.getFirstLastName(obj);

                case 'DrFirstLastNameDesignationNoSalutation':
                    obj.salutation = null;
                    return `${ph.getFirstLastName(obj)}${(!!obj.designation ? `, ${obj.designation}` : '')}`;

                case 'DrFirstLastNameDesignation':
                    return `${ph.getFirstLastName(obj)}${(!!obj.designation ? `, ${obj.designation}` : '')}`;

                default:
                    break;
            }
            break;

        case 'PhoneInternational':
        case 'PhoneSpace':
        case 'PhoneDash':
        case 'PhoneBrackets':
        case 'PhoneSpacePlusOne':
        case 'PhoneDashPlusOne':
        case 'PhoneBracketsPlusOne':
            let phoneParts = obj ? obj.split('x') : [''];
            let unformattedPhone = phoneParts[0].replace(/[^\d*]/g, '');
            let formattedPhone = unformattedPhone;

            switch (format) {
                case 'PhoneInternational':
                    if (unformattedPhone.length === 10) {
                        formattedPhone = `+1${unformattedPhone}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        formattedPhone = `+${unformattedPhone}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '*') {
                        formattedPhone = `+1${unformattedPhone.substr(1)}`;
                    }
                    break;

                case 'PhoneSpace':
                    if (unformattedPhone.length === 10) {
                        formattedPhone = `${(unformattedPhone.substr(0, 1) === '8' ? '1 ' : '')}${unformattedPhone.substr(0, 3)} ${unformattedPhone.substr(3, 3)} ${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        formattedPhone = `${(unformattedPhone.substr(1, 1) === '8' ? '1 ' : '')}${unformattedPhone.substr(1, 3)} ${unformattedPhone.substr(4, 3)} ${unformattedPhone.substr(7)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '*') {
                        formattedPhone = `${unformattedPhone.substr(1, 3)} ${unformattedPhone.substr(4, 3)} ${unformattedPhone.substr(7)}`;
                    }
                    break;

                case 'PhoneDash':
                    if (unformattedPhone.length === 10) {
                        formattedPhone = `${(unformattedPhone.substr(0, 1) === '8' ? '1-' : '')}${unformattedPhone.substr(0, 3)}-${unformattedPhone.substr(3, 3)}-${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        formattedPhone = `${(unformattedPhone.substr(1, 1) === '8' ? '1-' : '')}${unformattedPhone.substr(1, 3)}-${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '*') {
                        formattedPhone = `${unformattedPhone.substr(1, 3)}-${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    break;

                case 'PhoneBrackets':
                    if (unformattedPhone.length === 10) {
                        formattedPhone = `${(unformattedPhone.substr(0, 1) === '8' ? '1 ' : '')}(${unformattedPhone.substr(0, 3)}) ${unformattedPhone.substr(3, 3)}-${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        formattedPhone = `${(unformattedPhone.substr(1, 1) === '8' ? '1 ' : '')}(${unformattedPhone.substr(1, 3)}) ${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '*') {
                        formattedPhone = `(${unformattedPhone.substr(1, 3)}) ${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    break;

                case 'PhoneSpacePlusOne':
                    if (unformattedPhone.length === 10) {
                        formattedPhone = `1-${unformattedPhone.substr(0, 3)} ${unformattedPhone.substr(3, 3)} ${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        formattedPhone = `1-${unformattedPhone.substr(1, 3)} ${unformattedPhone.substr(4, 3)} ${unformattedPhone.substr(7)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '*') {
                        formattedPhone = `1-${unformattedPhone.substr(1, 3)} ${unformattedPhone.substr(4, 3)} ${unformattedPhone.substr(7)}`;
                    }
                    break;

                case 'PhoneDashPlusOne':
                    if (unformattedPhone.length === 10) {
                        formattedPhone = `1-${unformattedPhone.substr(0, 3)}-${unformattedPhone.substr(3, 3)}-${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        formattedPhone = `1-${unformattedPhone.substr(1, 3)}-${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '*') {
                        formattedPhone = `1-${unformattedPhone.substr(1, 3)}-${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    break;

                case 'PhoneBracketsPlusOne':
                    if (unformattedPhone.length === 10) {
                        formattedPhone = `1-(${unformattedPhone.substr(0, 3)}) ${unformattedPhone.substr(3, 3)}-${unformattedPhone.substr(6)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '1') {
                        formattedPhone = `1-(${unformattedPhone.substr(1, 3)}) ${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    else if (unformattedPhone.length === 11 && unformattedPhone[0] === '*') {
                        formattedPhone = `1-(${unformattedPhone.substr(1, 3)}) ${unformattedPhone.substr(4, 3)}-${unformattedPhone.substr(7)}`;
                    }
                    break;

                default:
                    break;
            }

            return `${formattedPhone}${phoneParts.length > 1 ? ` x${phoneParts[1]}` : ''}`;

        case 'DashYearMonthDay':
        case 'SlashYearMonthDay':
        case 'FullDate':
        case 'ShortDate':
        case 'FullDayOfWeekDate':
        case 'ShortDayOfWeekDate':
            let date = isMoment(obj) ? obj.clone() : moment(obj);

            if (date.isValid) {
                switch (format) {
                    case 'DashYearMonthDay':
                        return date.format("YYYY-MM-DD");

                    case 'SlashYearMonthDay':
                        return date.format("YYYY/MM/DD");

                    case 'FullDate':
                        return date.format("MMMM D, YYYY");

                    case 'ShortDate':
                        return date.format("MMM D, YYYY");

                    case 'FullDayOfWeekDate':
                        return date.format("dddd, MMMM Do, YYYY");

                    case 'ShortDayOfWeekDate':
                        return date.format("ddd, MMM Do, YYYY");

                    default:
                        break;
                }
            }
            break;

        case 'PlusMinus':
        case 'PlusMinusPlusZero':
            let field = obj;

            if (hasNumericValue(field)) {
                switch (format) {
                    case 'PlusMinus':
                        return `${(parseFloat(field) > 0 ? ' +' : '')}${field}`.replace('++', '+');

                    case 'PlusMinusPlusZero':
                        return `${(parseFloat(field) >= 0 ? ' +' : '')}${field}`.replace('++', '+');

                    default:
                        break;
                }
            }
            break;

        case 'LicenseOnly':
            return !!obj.licenseNumber ? `License No.: ${obj.licenseNumber}` : '';

        case 'BillingOnly':
            return !!obj.publicInsuranceProviderNumber ? `Billing No.: ${obj.publicInsuranceProviderNumber}` : '';

        case 'LicenseBilling':
            const licenseNumber = !!obj.licenseNumber ? `License No.: ${obj.licenseNumber}` : '';
            const billingNumber = !!obj.publicInsuranceProviderNumber ? `Billing No.: ${obj.publicInsuranceProviderNumber}` : '';

            if (!!licenseNumber && !!billingNumber) {
                return `${licenseNumber}, ${billingNumber}`;
            }

            if (!!licenseNumber && !billingNumber) {
                return `${licenseNumber}`;
            }

            if (!licenseNumber && !!billingNumber) {
                return `${billingNumber}`;
            }

            return '';

        case 'NoLicenseBilling':
            return '';

        default:
            break;
    }

    return defaultValue ? defaultValue : obj;
}