import { createContext } from 'react';
import { decorate, observable, action, computed } from 'mobx';

import api from '../api';
import * as fn from '../utilities/_functions';

export class CustomerView {
    id = null;
    loadRelatedProperties = true;
    data = null;
    patientProfile = null;
    purchases = [];
    orders = [];
    appointments = [];
    exams = [];
    prescriptions = [];
    pendingPrescriptions = [];
    printedDocuments = [];
    communications = [];
    relationships = [];
    referralAppointments = [];
    medicalReportResponses = [];
    notes = [];
    privateInsurances = [];
    newAddress = {
        geocode: null,
        streetNumber: null,
        streetName: null,
        unitSuiteType: null,
        unitSuite: null,
        line2: null,
        locality: null,
        sublocality: null,
        postalCode: null,
        region: null,
        regionCode: null,
        country: null,
        countryCode: null,
    };
    isLoading = false;
    isReady = false;

    // count
    isAppointmentsCountReady = false;
    isExamsCountReady = false;
    isPurchasesCountReady = false;
    isWorkOrdersCountReady = false;
    isPrescriptionsCountReady = false;
    isPrintedDocumentsCountReady = false;
    isCommunicationsCountReady = false;
    isReferralAppointmentsCountReady = false;
    isMedicalReportResponsesCountReady = false;
    isNotesCountReady = false;

    // loading
    isAppointmentsLoading = false;
    isExamsLoading = false;
    isPurchasesLoading = false;
    isWorkOrdersLoading = false;
    isPrescriptionsLoading = false;
    isPrintedDocumentsLoading = false;
    isCommunicationsLoading = false;
    isReferralAppointmentsLoading = false;
    isMedicalReportResponsesLoading = false;
    isNotesLoading = false;
    isRelationshipLoading = false;

    // ready
    isAppointmentsReady = false;
    isExamsReady = false;
    isPurchasesReady = false;
    isWorkOrdersReady = false;
    isPrescriptionsReady = false;
    isPrintedDocumentsReady = false;
    isCommunicationsReady = false;
    isReferralAppointmentsReady = false;
    isMedicalReportResponsesReady = false;
    isNotesReady = false;

    isPatientProfileReady = false;
    isRelationshipsReady = false;
    isRelationshipExamAppointmentReady = false;
    isPrivateInsuranceReady = false;

    cancelAppointments = null;
    cancelPrintedDocuments = null;
    cancelCommunications = null;
    cancelCustomer = null;
    cancelExams = null;
    cancelReferralAppointments = null;
    cancelMedicalReportResponses = null;
    cancelPrivateInsurance = null;
    cancelWorkOrders = null;
    cancelNotes = null;
    cancelPatientProfile = null;
    cancelPinNote = null;
    cancelPrescriptions = null;
    cancelPendingPrescriptions = null;
    cancelPurchases = null;
    cancelRelationships = null;

    initialize = (id, loadProperties = true) => {
        const that = this;
        this.clear();
        this.id = id;
        this.loadRelatedProperties = loadProperties === true ? true : false;

        return new Promise((resolve, reject) => {
            that.refresh(loadProperties)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isReady = true;
                })
        })
    }

    refresh = (loadProperties = true) => {
        const that = this;
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            api.Customers.get(
                this.id,
                (c) => { that.cancelCustomer = c }
            )
                .then(({ data }) => {
                    const requests = [];
                    that.data = data;

                    requests.push(that.loadPatientProfile(that.isPatientProfileReady));
                    requests.push(that.loadPrivateInsurance(that.isPrivateInsuranceReady));
                    requests.push(that.loadRelationships((that.isRelationshipsReady || that.isRelationshipExamAppointmentReady)));

                    if (that.loadRelatedProperties && loadProperties) {
                        requests.push(that.loadPurchases());
                        requests.push(that.loadWorkOrders());
                        requests.push(that.loadAppointments());
                        requests.push(that.loadExams());
                        requests.push(that.loadPrescriptions());
                        requests.push(that.loadPrintedDocuments());
                        requests.push(that.loadCommunications());
                        requests.push(that.loadReferralAppointments());
                        requests.push(that.loadMedicalReportResponses());
                        requests.push(that.loadNotes());
                    }

                    Promise.all(requests).then(() => {
                        that.loadPendingPrescriptions();
                        resolve();
                    });
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isLoading = false;
                })
        })
    }

    loadPatientProfile = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            if (that.data.patientProfileId) {
                api.PatientProfiles.get(
                    that.data.patientProfileId,
                    (c) => { that.cancelPatientProfile = c }
                )
                    .then(({ data }) => {
                        that.patientProfile = data;
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        that.isPatientProfileReady = true;
                    })
            } else {
                that.isPatientProfileReady = true;
                resolve();
            }
        })
    }

    loadAppointments = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isAppointmentsLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.Appointments.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'Status',
                            value: 'Booked,Canceled,NoShow,Completed',
                            operator: 'Contains'
                        },
                    ],
                    sortByFields: [{
                        field: 'StartUtc',
                        direction: 'DESC',
                    }, {
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    }],
                    includeNotes: true,
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelAppointments = c }
            )
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        that.appointments = data.result;
                    } else {
                        that.appointments.clear();
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isAppointmentsReady = true;
                        this.isAppointmentsLoading = false;
                    }

                    that.isAppointmentsCountReady = true;
                })
        })
    }

    loadExams = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isExamsLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.Exams.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                        {
                            field: 'Status', operator: '!=',
                            value: 'Deleted',
                        },
                    ],
                    sortByFields: [{
                        field: 'ScheduledStartUtc',
                        direction: 'DESC',
                    }, {
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    }],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelExams = c }
            )
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        that.exams = data.result;
                    } else {
                        that.exams.clear();
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isExamsReady = true;
                        this.isExamsLoading = false;
                    }

                    that.isExamsCountReady = true;
                })
        })
    }

    loadPurchases = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isPurchasesLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.Purchases.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        }
                    ],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    }, {
                        field: 'Number',
                        direction: 'DESC',
                    }],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelPurchases = c }
            )
                .then(({ data }) => {
                    if (loadProperties || !that.isPurchasesReady) {
                        if (data && data.result && data.result.length > 0) {
                            that.purchases = data.result;
                        } else {
                            that.purchases.clear();
                        }
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isPurchasesReady = true;
                        that.isPurchasesLoading = false;
                    }

                    that.isPurchasesCountReady = true;
                })
        })
    }

    loadWorkOrders = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isWorkOrdersLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.WorkOrders.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                    ],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    }, {
                        field: 'Number',
                        direction: 'DESC',
                    }],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelWorkOrders = c }
            )
                .then(({ data }) => {
                    if (loadProperties || !that.isWorkOrdersReady) {
                        if (data && data.result && data.result.length > 0) {
                            that.orders = data.result;
                        } else {
                            that.orders.clear();
                        }
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isWorkOrdersReady = true;
                        this.isWorkOrdersLoading = false;
                    }

                    that.isWorkOrdersCountReady = true;
                })
        })
    }

    loadPrescriptions = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isPrescriptionsLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.Prescriptions.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                    ],
                    sortByFields: [{
                        field: 'WrittenDate',
                        direction: 'DESC',
                    },],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelPrescriptions = c }
            )
                .then(({ data }) => {
                    if (loadProperties || !that.isPrescriptionsReady) {
                        if (data && data.result && data.result.length > 0) {
                            that.prescriptions = data.result;
                        } else {
                            that.prescriptions.clear();
                        }
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isPrescriptionsReady = true;
                        this.isPrescriptionsLoading = false;
                    }

                    that.isPrescriptionsCountReady = true;
                })
        })
    }

    loadPendingPrescriptions = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            api.Prescriptions.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                        {
                            field: 'RequestFinalizeDateUtc',
                            operator: '!=',
                            value: null,
                        },
                        {
                            field: 'FinalizedDateUtc',
                            value: null,
                        },
                        {
                            field: 'IsOutsidePrescription',
                            value: false,
                        },
                    ],
                    sortByFields: [{
                        field: 'RequestFinalizeDateUtc',
                        direction: 'DESC',
                    },],
                    includeTotalCount: false,
                    loadProperties: true,
                },
                (c) => { that.cancelPendingPrescriptions = c }
            )
                .then(({ data }) => {
                    if (data && data.result && data.result.length > 0) {
                        that.pendingPrescriptions = data.result;
                    } else {
                        that.pendingPrescriptions.clear();
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
        })
    }

    loadPrintedDocuments = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isPrintedDocumentsLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.PrintedDocuments.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                    ],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    },],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelPrintedDocuments = c }
            )
                .then(({ data }) => {
                    if (loadProperties || !that.isPrintedDocumentsReady) {
                        if (data && data.result && data.result.length > 0) {
                            that.printedDocuments = data.result;
                        } else {
                            that.printedDocuments.clear();
                        }
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isPrintedDocumentsReady = true;
                        this.isPrintedDocumentsLoading = false;
                    }

                    that.isPrintedDocumentsCountReady = true;
                })
        })
    }

    loadCommunications = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isCommunicationsLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.Communications.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'Status',
                            operator: '!=',
                            value: 'Hidden',
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                    ],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    },],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelCommunications = c }
            )
                .then(({ data }) => {
                    if (loadProperties || !that.isCommunicationsReady) {
                        if (data && data.result && data.result.length > 0) {
                            that.communications = data.result;
                        } else {
                            that.communications.clear();
                        }
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isCommunicationsReady = true;
                        this.isCommunicationsLoading = false;
                    }

                    that.isCommunicationsCountReady = true;
                })
        })
    }

    loadReferralAppointments = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isReferralAppointmentsLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.ReferralAppointments.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                    ],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    },],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelReferralAppointments = c }
            )
                .then(({ data }) => {
                    if (loadProperties || !that.isReferralAppointmentsReady) {
                        if (data && data.result && data.result.length > 0) {
                            that.referralAppointments = data.result;
                        } else {
                            that.referralAppointments.clear();
                        }
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isReferralAppointmentsReady = true;
                        this.isReferralAppointmentsLoading = false;
                    }

                    that.isReferralAppointmentsCountReady = true;
                })
        })
    }

    loadMedicalReportResponses = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isMedicalReportResponsesLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.MedicalReportResponses.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                    ],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    },],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelMedicalReportResponses = c }
            )
                .then(({ data }) => {
                    if (loadProperties || !that.isMedicalReportResponsesReady) {
                        if (data && data.result && data.result.length > 0) {
                            that.medicalReportResponses = data.result;
                        } else {
                            that.medicalReportResponses.clear();
                        }
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isMedicalReportResponsesReady = true;
                        this.isMedicalReportResponsesLoading = false;
                    }

                    that.isMedicalReportResponsesCountReady = true;
                })
        })
    }

    loadNotes = (loadProperties = false) => {
        const that = this;

        if (loadProperties) {
            this.isNotesLoading = true;
        }

        return new Promise((resolve, reject) => {
            api.Notes.search(
                {
                    parameters: [
                        {
                            field: 'CustomerId',
                            value: that.id,
                        },
                        {
                            field: 'DeactivatedDateUtc',
                            value: null,
                        },
                    ],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'ASC',
                    },],
                    includeTotalCount: false,
                    loadProperties: loadProperties,
                },
                (c) => { that.cancelNotes = c }
            )
                .then(({ data }) => {
                    if (loadProperties || !that.isNotesReady) {
                        if (data && data.result && data.result.length > 0) {
                            that.notes = data.result;
                            that.data.notes = data.result;
                        } else {
                            that.notes.clear();

                            if (that.data.notes && that.data.notes.length > 0) {
                                that.data.notes.clear();
                            }
                        }
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    if (loadProperties) {
                        that.isNotesReady = true;
                        this.isNotesLoading = false;
                    }

                    that.isNotesCountReady = true;
                })
        })
    }

    loadRelationships = (loadProperties = false) => {
        const that = this;

        that.isRelationshipLoading = true;

        return new Promise((resolve, reject) => {
            api.CustomerRelationships.get(
                that.id,
                loadProperties,
                true,
                (c) => { that.cancelRelationships = c }
            )
                .then(({ data }) => {
                    if (data && data.length > 0) {
                        that.relationships = data;
                    } else {
                        that.relationships.clear();
                    }
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isRelationshipLoading = false;
                    that.isRelationshipsReady = true;

                    if (loadProperties) {
                        that.isRelationshipExamAppointmentReady = true;
                    }
                })
        })
    }

    loadPrivateInsurance = () => {
        const that = this;

        return new Promise((resolve, reject) => {
            api.CustomerPrivateInsurances.search(
                {
                    parameters: [{
                        field: 'CustomerId',
                        value: that.id,
                    }],
                    sortByFields: [{
                        field: 'CreatedDateUtc',
                        direction: 'DESC',
                    }],
                    includeTotalCount: false,
                },
                (c) => { that.cancelPrivateInsurance = c }
            )
                .then(({ data }) => {
                    that.privateInsurances.clear();

                    if (data && data.result && data.result.length > 0) {
                        that.privateInsurances = data.result;
                    }
                    
                    resolve();
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    that.isPrivateInsuranceReady = true;
                })
        })
    }

    pinNote = (id, isPinned, notify) => {
        const that = this;

        if (!!notify) {
            this.isSaving = true;
        }

        return new Promise((resolve, reject) => {
            api.Notes.pin(
                id,
                { isPinned: isPinned },
                (c) => { that.cancelPinNote = c }
            )
                .then(() => {
                    that.refresh();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    that.isSaving = false;
                })
        })
    }

    clear = () => {
        this.id = null;
        this.data = null;
        this.loadRelatedProperties = false;
        this.patientProfile = null;
        this.purchases.clear();
        this.orders.clear();
        this.appointments.clear();
        this.exams.clear();
        this.prescriptions.clear();
        this.pendingPrescriptions.clear();
        this.printedDocuments.clear();
        this.communications.clear();
        this.relationships.clear();
        this.referralAppointments.clear();
        this.medicalReportResponses.clear();
        this.notes.clear();
        this.privateInsurances.clear();
        this.isLoading = false;
        this.isReady = false;
        this.clearReadyFlags();
        this.clearNewAddress();

        if (fn.isFunction(this.cancelAppointments)) {
            this.cancelAppointments();
            this.cancelAppointments = null;
        }

        if (fn.isFunction(this.cancelPrintedDocuments)) {
            this.cancelPrintedDocuments();
            this.cancelPrintedDocuments = null;
        }

        if (fn.isFunction(this.cancelCommunications)) {
            this.cancelCommunications();
            this.cancelCommunications = null;
        }

        if (fn.isFunction(this.cancelCustomer)) {
            this.cancelCustomer();
            this.cancelCustomer = null;
        }

        if (fn.isFunction(this.cancelExams)) {
            this.cancelExams();
            this.cancelExams = null;
        }

        if (fn.isFunction(this.cancelReferralAppointments)) {
            this.cancelReferralAppointments();
            this.cancelReferralAppointments = null;
        }

        if (fn.isFunction(this.cancelMedicalReportResponses)) {
            this.cancelMedicalReportResponses();
            this.cancelMedicalReportResponses = null;
        }

        if (fn.isFunction(this.cancelPrivateInsurance)) {
            this.cancelPrivateInsurance();
            this.cancelPrivateInsurance = null;
        }

        if (fn.isFunction(this.cancelWorkOrders)) {
            this.cancelWorkOrders();
            this.cancelWorkOrders = null;
        }

        if (fn.isFunction(this.cancelNotes)) {
            this.cancelNotes();
            this.cancelNotes = null;
        }

        if (fn.isFunction(this.cancelPatientProfile)) {
            this.cancelPatientProfile();
            this.cancelPatientProfile = null;
        }

        if (fn.isFunction(this.cancelPinNote)) {
            this.cancelPinNote();
            this.cancelPinNote = null;
        }

        if (fn.isFunction(this.cancelPrescriptions)) {
            this.cancelPrescriptions();
            this.cancelPrescriptions = null;
        }

        if (fn.isFunction(this.cancelPendingPrescriptions)) {
            this.cancelPendingPrescriptions();
            this.cancelPendingPrescriptions = null;
        }

        if (fn.isFunction(this.cancelPurchases)) {
            this.cancelPurchases();
            this.cancelPurchases = null;
        }

        if (fn.isFunction(this.cancelRelationships)) {
            this.cancelRelationships();
            this.cancelRelationships = null;
        }
    }

    clearReadyFlags = () => {
        // count
        this.isAppointmentsCountReady = false;
        this.isExamsCountReady = false;
        this.isPurchasesCountReady = false;
        this.isWorkOrdersCountReady = false;
        this.isPrescriptionsCountReady = false;
        this.isPrintedDocumentsCountReady = false;
        this.isCommunicationsCountReady = false;
        this.isReferralAppointmentsCountReady = false;
        this.isMedicalReportResponsesCountReady = false;
        this.isNotesCountReady = false;
        // loading
        this.isAppointmentsLoading = false;
        this.isExamsLoading = false;
        this.isPurchasesLoading = false;
        this.isWorkOrdersLoading = false;
        this.isPrescriptionsLoading = false;
        this.isPrintedDocumentsLoading = false;
        this.isCommunicationsLoading = false;
        this.isReferralAppointmentsLoading = false;
        this.isMedicalReportResponsesLoading = false;
        this.isNotesLoading = false;
        this.isRelationshipLoading = false;
        // ready
        this.isAppointmentsReady = false;
        this.isExamsReady = false;
        this.isPurchasesReady = false;
        this.isWorkOrdersReady = false;
        this.isPrescriptionsReady = false;
        this.isPrintedDocumentsReady = false;
        this.isCommunicationsReady = false;
        this.isReferralAppointmentsReady = false;
        this.isMedicalReportResponsesReady = false;
        this.isNotesReady = false;

        this.isPatientProfileReady = false;
        this.isRelationshipsReady = false;
        this.isRelationshipExamAppointmentReady = false;
        this.isPrivateInsuranceReady = false;
    }

    clearNewAddress = () => {
        this.newAddress.geocode = null;
        this.newAddress.streetNumber = null;
        this.newAddress.streetName = null;
        this.newAddress.unitSuiteType = null;
        this.newAddress.unitSuite = null;
        this.newAddress.line2 = null;
        this.newAddress.locality = null;
        this.newAddress.sublocality = null;
        this.newAddress.postalCode = null;
        this.newAddress.region = null;
        this.newAddress.regionCode = null;
        this.newAddress.country = null;
        this.newAddress.countryCode = null;
    }

    get displayDuplicateWarning() {
        if (this.data && this.data.isActive) {
            return this.suggestDuplicateCustomerIds.length > 0;
        }

        return false;
    }

    get suggestDuplicateCustomerIds() {
        let suggestIds = [];

        if (this.data && this.data.isActive) {
            const hiddenDuplicateCustomerIds = this.data.hiddenDuplicateCustomerIds && this.data.hiddenDuplicateCustomerIds ? this.data.hiddenDuplicateCustomerIds : [];

            if (this.data.duplicateCustomerIds && this.data.duplicateCustomerIds.length > 0) {
                suggestIds = this.data.duplicateCustomerIds.filter(d => !hiddenDuplicateCustomerIds.some(h => h === d));
            }
        }

        return suggestIds && suggestIds.length > 0 ? suggestIds : [];
    }

    get isNotFound() {
        return this.isReady && !this.data;
    }
}

decorate(CustomerView, {
    id: observable,
    data: observable,
    loadRelatedProperties: observable,
    patientProfile: observable,
    purchases: observable.deep,
    orders: observable.deep,
    appointments: observable.deep,
    exams: observable.deep,
    prescriptions: observable.deep,
    pendingPrescriptions: observable.deep,
    printedDocuments: observable.deep,
    communications: observable.deep,
    relationships: observable.deep,
    referralAppointments: observable.deep,
    medicalReportResponses: observable.deep,
    notes: observable.deep,
    privateInsurances: observable,
    newAddress: observable.deep,
    isLoading: observable,
    isReady: observable,
    // count
    isAppointmentsCountReady: observable,
    isExamsCountReady: observable,
    isPurchasesCountReady: observable,
    isWorkOrdersCountReady: observable,
    isPrescriptionsCountReady: observable,
    isPrintedDocumentsCountReady: observable,
    isCommunicationsCountReady: observable,
    isReferralAppointmentsCountReady: observable,
    isMedicalReportResponsesCountReady: observable,
    isNotesCountReady: observable,
    // loading
    isAppointmentsLoading: observable,
    isExamsLoading: observable,
    isPurchasesLoading: observable,
    isWorkOrdersLoading: observable,
    isPrescriptionsLoading: observable,
    isPrintedDocumentsLoading: observable,
    isCommunicationsLoading: observable,
    isReferralAppointmentsLoading: observable,
    isMedicalReportResponsesLoading: observable,
    isNotesLoading: observable,
    isRelationshipLoading: observable,
    // ready
    isAppointmentsReady: observable,
    isExamsReady: observable,
    isPurchasesReady: observable,
    isWorkOrdersReady: observable,
    isPrescriptionsReady: observable,
    isPrintedDocumentsReady: observable,
    isCommunicationsReady: observable,
    isReferralAppointmentsReady: observable,
    isMedicalReportResponsesReady: observable,
    isNotesReady: observable,

    isPatientProfileReady: observable,
    isRelationshipsReady: observable,
    isRelationshipExamAppointmentReady: observable,
    isPrivateInsuranceReady: observable,
    initialize: action,
    clear: action,
    clearReadyFlags: action,
    clearNewAddress: action,
    loadPatientProfile: action,
    loadAppointments: action,
    loadExams: action,
    loadPurchases: action,
    loadPrescriptions: action,
    loadPendingPrescriptions: action,
    loadRelationships: action,
    loadPrintedDocuments: action,
    loadCommunications: action,
    loadPrivateInsurance: action,
    loadNotes: action,
    pinNote: action,
    displayDuplicateWarning: computed,
    suggestDuplicateCustomerIds: computed,
    isNotFound: computed,
})

export default createContext(new CustomerView());